<template>
  <div @mousemove="handleMouseMove">
  <v-main class="1pt-0" >
       <div class="cursor" v-if="construction && tata" :style="dynamicStyles"></div>
        <div class="cursora" v-if="construction && tata" :style="dynamicStyles"></div>
       <Navbar v-if="construction && !tata" />
       <MaterialNavbar v-if="materialLoad" />
       <InteriorNavbar v-if="interiorLoad" />
       <DroneNavbar v-if="droneLoad" />
       <GeoLocationNavbar v-if="GeoLocationLoad" />
       <TataNavbar v-if="construction && tata"/>
       <VtourNavbar v-if="VtourLoad" />
     <router-view></router-view>
        
  </v-main>
  </div>
</template>

<script>
import Navbar from "../../components/Headers/Navbar";
import TataNavbar from "../../components/Headers/TataNavbar";
import MaterialNavbar from "../../components/Headers/MaterialNavbar";
import InteriorNavbar from "../../components/Headers/InteriorNavbar";
import DroneNavbar from "../../components/Headers/DroneNavbar";
import GeoLocationNavbar from "../../components/Headers/GeoLocationNavbar";
import VtourNavbar from "../../components/Headers/VtourNavbar";
export default {
  name: "Mainlayout",
  components: { Navbar, MaterialNavbar, InteriorNavbar, DroneNavbar,GeoLocationNavbar,TataNavbar,VtourNavbar},
 
  created() {
         var rout =  this.$route.name;  
          this.moduleChecker(rout);
   },
  watch: {
        '$route': function (to) {
           this.moduleChecker(to.name);
        }
    },
    
  data: () => ({
       materialLoad : false,
       interiorLoad : false,
       droneLoad : false,
       construction : false,
       GeoLocationLoad : false,
       tata : false,
       VtourLoad: false,
       mouseX: 50,
      mouseY: 50,
      dynamicStyles:{}
    // routename : this.$route.name,
  }),
   methods: {
      moduleChecker(rout){
         // console.log("current Router is", rout);
         var chenal_partner = window.location.hostname;
         var check_tpls = window.location.href; 
         console.log("chenal_partner",chenal_partner);

        //  if(chenal_partner == "localhost"){
        //   this.tata = true;
        //  }
       var tplshave =  check_tpls.includes('tpls');
       console.log("tplshave",tplshave);
         if(tplshave){
          this.tata = true;
         }
        var currentmodule = rout.split("_");
      
         // console.log("module", currentmodule);
          if(currentmodule[0] == "material"){
              this.materialLoad = true;
              this.interiorLoad = false;
              this.droneLoad = false;
              this.construction = false;
              this.GeoLocationLoad = false;
              this.VtourLoad = false;

            this.$store.dispatch('curentModule','material')
            
            // console.log(this.$store.state.currentModule);
          }else if(currentmodule[0] == "interior"){
              this.materialLoad = false;
              this.interiorLoad = true;
              this.droneLoad = false; 
              this.construction = false;
              this.GeoLocationLoad = false;
              this.VtourLoad = false;

              
            this.$store.dispatch('curentModule','interior')
          }
          else if(currentmodule[0] == "drone"){
                  
              this.materialLoad = false;
              this.interiorLoad = false;
              this.droneLoad = true;
              this.construction = false;
              this.GeoLocationLoad = false;
              this.VtourLoad = false;
              
            this.$store.dispatch('curentModule','drone')
                     
          }
          else if(currentmodule[0] == "geo"){
                  
              this.materialLoad = false;
              this.interiorLoad = false;
              this.droneLoad = false;
              this.construction = false;
              this.GeoLocationLoad = true;
              this.VtourLoad = false;
              
            this.$store.dispatch('curentModule','geo')
                     
          }else if(currentmodule[0] == "vtour"){
                  
                  this.materialLoad = false;
                  this.interiorLoad = false;
                  this.droneLoad = false;
                  this.construction = false;
                  this.GeoLocationLoad = false;
                  this.VtourLoad = true;
                  
                this.$store.dispatch('curentModule','vtour')
                         
              }else{
           //  console.log("Dashboard");
              this.materialLoad = false;
              this.interiorLoad = false;
              this.droneLoad = false;
              this.VtourLoad = false;
              this.construction = true;
            //  this.$router.push("/dashboard");

          }
          
      },
      handleMouseMove(event) {
      // Update mouseX and mouseY with the current mouse position
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      this.dynamicStyles = {
        top: this.mouseY+'px',
        left: this.mouseX+'px'
      }
     //  console.log(event.clientX,event.clientY);
    } 
   }
};
</script>
<style>

</style>