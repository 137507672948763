<template>
  
  <div>
    <Preloader v-if="preloader" />
  
      <v-row>
      <v-col
        cols="12"
        md="3"
      >
<!-----------------For Desktop view-------------------------------->
        <v-card
          class="vhfix desktop"
          
        >
         <v-card-title>
      {{lang.ManageUsers }} 
    </v-card-title>
           <v-list class="desktop"
         >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
      <v-list-item  v-for="(item, i) in items"
           :key="i"
          active-class="actiaveitem" color="amber accent-3"   @click="getUsersByProject(item)" > 
       <template >
          <v-list-item-content>
            <v-list-item-title v-text="item.project_name"></v-list-item-title>
          </v-list-item-content>
           <v-list-item-icon>
            <v-icon>mdi-arrow-right-bold-circle</v-icon>
          </v-list-item-icon>
        </template>
      </v-list-item>  
    </v-list-item-group>
    </v-list>       
</v-card>
<!-----------------For Mobile view-------------------------------->
        <v-card
          class="vhfix mobileonly">
            <v-select :items="items" class="ma-2 filterb"  item-text="project_name" item-value="project_name" small label="Select Project" dense solo v-model="selected_project" @change="getUsersByProject(event)" return-object single-line title="Select Project"></v-select>
        </v-card> 
<!-----------------For Tab view-------------------------------->
         <v-card
          class="vhfix tabonly">
            <v-select :items="items" class="ma-2 filterb"  item-text="project_name" item-value="project_name" small label="Select Project" dense solo v-model="selected_project" @change="getUsersByProject(event)" return-object single-line title="Select Project"></v-select>
        </v-card>  
      </v-col>
<!-----------------General view for all device-------------------------------->
      <v-col
        cols="12"
        md="9"
      >
        <v-card
          class="vhfix"
         >
            <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="lang.Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="firstname"
    class="elevation-1"
    :search="search"
  >
     <template v-slot:item.active="{ item }">
      <v-chip
        :color="getColor(item.active)"
        dark
      >
        {{ item.active == 1 ? 'Active' : 'Deactive' }}
      </v-chip>
    </template>
    <template v-slot:item.user_code="{ item }">
      <v-chip
        :color="getColor(item.user_code)"
        dark
      >
        {{ item.user_code == 1 ? lang.User : item.user_code == 0 ? lang.Admin: ''}}
      </v-chip>
    </template>
     <template v-slot:item.cont_camera_aceess="{ item }">
       <v-chip
        :color="getColor(item.cont_camera_aceess)"
        dark
        class="ma-1"
        v-if="activeProjectData.const_camera"
      >
        Const camera
      </v-chip>
        <v-chip
        :color="getColor(item.interior_access)"
        dark
        class="ma-1"
         v-if="activeProjectData.interior"
      >
        Interior 
      </v-chip>
       <v-chip
        :color="getColor(item.interior_editor_access)"
        dark
        class="ma-1"
         v-if="activeProjectData.interior"
      >
        Interior Editor
      </v-chip>

      <v-chip
        :color="getColor(item.drone_access)"
        dark
        class="ma-1"
         v-if="activeProjectData.drone"
      >
        Drone 
      </v-chip>
       <v-chip
        :color="getColor(item.drone_edit_access)"
        dark
        class="ma-1"
        v-if="activeProjectData.drone"
      >
        Drone Editor
      </v-chip>

      <v-chip
        :color="getColor(item.material_access)"
        dark
        class="ma-1"
         v-if="activeProjectData.material"
      >
        Material
      </v-chip>

      <v-chip
        :color="getColor(item.billing_access)"
        dark
        class="ma-1"
      >
        Invoice
      </v-chip>

       <v-chip
        :color="getColor(item.linear_monitoring_access)"
        dark
        class="ma-1"
         v-if="activeProjectData.linear_monitoring"
      >
        Linear Monitoring
      </v-chip>
       <v-chip
        :color="getColor(item.linear_monitoring_edit)"
        dark
        class="ma-1"
        v-if="activeProjectData.linear_monitoring"
      >
        Linear Editor
      </v-chip>
      <v-chip
        :color="getColor(item.virtual_tour_access)"
        dark
        class="ma-1"
        v-if="activeProjectData.virtual_tour"
      >
        Virtual Tour
      </v-chip>

      
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
         v-if="userdata.user_code == 2"
      >
        mdi-pencil
      </v-icon>
      <v-btn
        small
        class="mr-2"
        @click="logs(item)"
         v-if="userdata.user_code == 2"
      >
        Logs
      </v-btn>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
         v-if="userdata.user_code == 1 || userdata.user_code == 0"
      >
        mdi-eye
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
       
      >
        {{lang.Reset}}
      </v-btn>
    </template>
  </v-data-table> 
        </v-card>
      </v-col>
      
    </v-row>

      <v-dialog
      v-model="logstable"
      persistent
      max-width="1280"
     
    >
        <v-btn class="closerlogs" @click="logstable = false"> <v-icon >mdi-window-close</v-icon></v-btn> 
       <v-card  class="myeditform">
     
     <v-card-title>
           <h3 class="uurlogin">{{ logitem.firstname }} {{ logitem.lastname }}</h3> 
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="tolp"
      ></v-text-field>
       <v-col class="datepicker"  cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                <!-- <ejs-daterangepicker :change="onValueChange" :minDays="min" :maxDays="max" :strictMode="mode" :placeholder="lang.selectRange">

                </ejs-daterangepicker> -->

                <date-range-picker
          @update="onValueChange"
          :ranges = "false"
          opens="left"
          :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
    v-model="chartdate"
    :date-format="dateFormat"
  ></date-range-picker>
               
            </v-col>    
    </v-card-title>
    <v-data-table
    :headers="logsheaders"
    :items="logsdesserts"
  
    class="elevation-1"
    :search="logsearch"
  >

   <template v-slot:item.created_at="{ item }">
    
        <!-- {{ gmttoisd(item.created_at) }} -->
          {{ item.created_at }}
    
    </template>
    
  </v-data-table> 

         <v-btn  @click="getUserLogs('')"  v-if="logsdesserts.length == 0">
           {{lang.Reset}}
        </v-btn>

         </v-card >  
      </v-dialog>   
        <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
     
    >
      <v-card  class="myeditform">
        <v-card-title>
          <span class="headline">{{lang.UserProfile}}</span>
          <v-alert
      dense
      text
      :type="alertType"
      v-if="alert"
    >
     {{ alertmessage }}
    </v-alert>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  :label="lang.FirstName"
                  required
                  v-model="firstname"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  :label="lang.LastName"
                  required
                  v-model="lastname"
                ></v-text-field>
              </v-col>
              
              <v-col cols="12"
               sm="6"
                md="6"
                >
                <v-text-field
                  :label="lang.Email"
                  required
                  readonly
                  v-model="email"
                 
                  :hint="lang.emailhint"
                ></v-text-field>
              </v-col>
              <v-col cols="12"
               sm="6"
                md="6" 
                >
                <v-text-field
                  :label="lang.Contact"
                
                   v-model="contactNo"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="userType"
                  :items="userTypes"
                  :label="lang.UserType"
                  item-text="usertitle"
                  item-value="id"
                  required
                   :readonly="userdata.user_code == 2 ? false : true"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
          v-model="e7"
          :items="items"
          :label="lang.Projects"
          item-text="project_name"
          item-value="id"
          multiple
          chips
          :hint="lang.selectuserHint"
          persistent-hint
          :readonly="userdata.user_code == 2 ? false : true"
        >
        </v-select>
              </v-col>
               <!-- <v-col
                cols="12"
                sm="4"
              >
          <v-checkbox
          v-model="constcameracheck"
          label="Const Camera"
          ></v-checkbox>
                </v-col>
                 <v-col
                cols="12"
                sm="4"
              >
          <v-checkbox
          v-model="billingcheck"
          label="Billing"
          ></v-checkbox>
                </v-col> -->
                
                 <v-col
                cols="12"
                sm="6"
               class="ma-0 pa-0"
              >
               
          <v-checkbox
          v-model="activecheck"
          :label="lang.Active"
          ></v-checkbox>
         
                </v-col>


                 <v-col
                  cols="12"
                  sm="6"
                   class="ma-0 pa-0"
                  v-if="userdata.cont_camera_aceess == 1 && activeProjectData.const_camera == 1 && (activeProjectData.material  == 1 || activeProjectData.interior == 1)"
                  >
                 
                  <v-checkbox
                  v-model="constcameraaccess"
                  :label="lang.conscamaccess"
                  ></v-checkbox>
                </v-col>

                  <v-col
                cols="12"
                sm="6"
                 class="ma-0 pa-0"
              v-if="userdata.material_access == 1 &&  activeProjectData.material == 1"
              >
                
          <v-checkbox
          v-model="materialaccess"
          :label="lang.MaterialAccess"
          ></v-checkbox>

                </v-col>
                
                 <v-col
                cols="12"
                sm="6"
                 class="ma-0 pa-0"
                 v-if="userdata.billing_access == 1"
              >
           
           <v-checkbox
          v-model="billingcheck"
          :label="lang.Billing"
          ></v-checkbox>
         
                </v-col>

                <v-col
                cols="12"
                sm="6"
                 class="ma-0 pa-0"
                  v-if="userdata.interior_access == 1 && activeProjectData.interior == 1"
              >
               
          <v-checkbox
          v-model="interiorviewaccess"
          :label="lang.InteriorviewAccess"
          ></v-checkbox>

                </v-col>
                  <v-col
                cols="12"
                sm="6"
                 class="ma-0 pa-0"
                   v-if="userdata.interior_editor_access == 1 && activeProjectData.interior == 1"
              >
           <v-checkbox
          v-model="interioreditaccess"
          :label="lang.InteriorEditAccess"
          ></v-checkbox>
         
         
                </v-col>

                <v-col
                cols="12"
                sm="6"
                 class="ma-0 pa-0"
                   v-if="userdata.drone_access == 1 && activeProjectData.drone == 1"
              >
               
          <v-checkbox
          v-model="droneviewaccess"
          :label="lang.DroneviewAccess"
          ></v-checkbox>

                </v-col>
                  <v-col
                cols="12"
                sm="6"
                 class="ma-0 pa-0"
                  v-if="userdata.drone_edit_access == 1 && activeProjectData.drone == 1"
              >
           <v-checkbox
          v-model="droneeditaccess"
          :label="lang.DroneEditAccess"
          ></v-checkbox>
         

                </v-col>
    
             <v-col
                cols="12"
                sm="6"
                 class="ma-0 pa-0"
                   v-if="userdata.linear_monitoring_access == 1 && activeProjectData.linear_monitoring == 1"
              >
               
          <v-checkbox
          v-model="linearviewaccess"
          label="Linear View Access"
          ></v-checkbox>

                </v-col>
                  <v-col
                cols="12"
                sm="6"
                 class="ma-0 pa-0"
                  v-if="userdata.linear_monitoring_edit == 1 && activeProjectData.linear_monitoring == 1"
              >
           <v-checkbox
          v-model="lineareditaccess"
          :label="lang.LinearEditAccess"
          ></v-checkbox>
         
         
                </v-col>     
                 

                 
            </v-row>
          </v-container>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
           {{lang.Close}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="UpdateUserInfo"
            v-if="userdata.user_code == 2"
          >
           {{ lang.Save }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   </div>
</template>
<script>
import axios from 'axios'
import Preloader from '../../../components/PreLoader'
//import Sidebar from '../../../components/SettingSidebar'
import { locals } from "../../../utils/locals";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
 
export default {
  components: {
    Preloader,
    DateRangePicker

  },
  name: "ChangePassword",
  data() {
    return { 
      preloader : false,
       items: {} ,
       selected_project:'',
       selectedItem: 0, 
       search: '',
        dialog: false,
      dialogDelete: false,
       e7 :[],
        headers: [
          {
            text: 'First Name',
            align: 'start',
            value: 'firstname',
          },
          { text: 'Last Name ', value: 'lastname' },
          { text: 'Email', value: 'email' },
          { text: 'Active', value: 'active' },
          { text: 'User type', value: 'user_code' },
          { text: 'User Access', value: 'cont_camera_aceess' },

          { text: 'Actions', value: 'actions', sortable: false, width: 150, }
        ],
    
     desserts: [],
     editedIndex: -1,
    
      editDate : {},
      userType : 1,
      userTypes : [
         {
           id : 1,
           usertitle : 'User'
         },
         {
           id : 0,
           usertitle : 'Admin'
         }
      ],
      constcameracheck : false,
      billingcheck : false,
      activecheck : false,
      materialaccess : false,
      interiorviewaccess :  false,
      interioreditaccess: false,

      droneviewaccess :  false,
      droneeditaccess: false,

      lineareditaccess : false,
      linearviewaccess : false,
      
      constcameraaccess : false,
       cont_camera_aceess : false,
      firstname : '',
      lastname : '',
      contactNo : '',
       alert: false,
          alertmessage: '',
          alertType: '', 
          userdata :'',
          activeProjectData:'asdfasfsda',
          email : '',
          logstable : false,

           logssearch: '',
      
        logsheaders: [
          {
            text: 'Project',
            align: 'start',
            value: 'project_name',
          },
          { text: 'Module', value: 'project_type' },
          { text: 'Page', value: 'visit_page' },
          { text: 'Device' , value : 'device'},
          { text: 'Date', value: 'created_at' }
        ],
             logsdesserts : [],
            max: '8',
            min: '1',
            waterMark: 'Select a Range',
            logitem : {},
              Local :locals,  
             lang : {}, 
          updateresp:'',
          chartdate : {}
    }
  },
  mounted() {
      var selectleng = this.$cookies.get('currentLanguage');
     this.lang = locals[selectleng];
     this.CurrentDateFunction();
        this.headers = [
          {
            text: this.lang.FirstName,
            align: 'start',
            value: 'firstname',
          },
          { text:  this.lang.LastName, value: 'lastname' },
          { text: this.lang.Email, value: 'email' },
          { text: this.lang.Active, value: 'active' },
          { text: this.lang.UserType, value: 'user_code' },
          { text: this.UserAccess, value: 'cont_camera_aceess' },

          { text: this.lang.Actions, value: 'actions', sortable: false, width: 150, }
        ]

     this.getPorjects();
       this.userdata = JSON.parse(localStorage.getItem('userdata')); 
      
     if(this.userdata.free_access == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }
        if (this.userdata.open_access == 1) {
          this.$router.push('/dashboard')
        }        
      /*  if(this.userdata.user_code == 2 || this.userdata.user_code == 0){
          this.useraccess = true
        }else{
          this.$router.push('/')
        }   */
  },
  watch: {
    
  '$store.state.currentCamera': function() {
    
     console.log("Current data get")
    
  },
  
   dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    
  },
   computed: {
      params() {
      return {
        firstname : this.firstname, 
        lastname : this.lastname,
        contact : this.contactNo,
        active :  this.activecheck ? 1:0,
        project_ids : this.e7.toString(),
        user_code : this.userType,
        material_access : this.materialaccess ? 1:0,
        interior_access :  this.interiorviewaccess ? 1:0,
        interior_editor_access: this.interioreditaccess ? 1:0,
        drone_access :  this.droneviewaccess ? 1:0,
        drone_editor_access: this.droneeditaccess ? 1:0,
        billing_access: this.billingcheck ? 1:0,
        billing_notification: this.billing_notification ? 1:0,
        linear_monitoring_edit : this.lineareditaccess ? 1:0,
        linear_monitoring_access: this.linearviewaccess ? 1:0,
        cont_camera_aceess: this.constcameraaccess ? 1:0
        
      }
    }
    },
  methods: {
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() >= new Date()
        }
        return classes
      },
     
      CurrentDateFunction(){
          var today = new Date();
          today.setDate(today.getDate() - 7);
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
         var start = mm+'-' +dd+'-'+yyyy;

              var date = new Date();
             
              var edd = String(date.getDate()).padStart(2, '0');
          var emm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
          var eyyyy = date.getFullYear();
          var end = emm+'-'+edd+'-'+eyyyy;

           console.log("startdate,",start, end);

   
            this.chartdate = {
              startDate : start,
               endDate : end
            }

      },
     getPorjects: function () {
        axios({ url: '/project', method: 'GET' })
          .then(resp => {
             console.log("Projects", resp.data.success);
            this.items = resp.data.success.filter(function(item){
                return item.project_active == 1;         
              });

              this.getUsersByProject(this.items[0]);
               this.activeProjectData = this.items[0]; 
          })
          .catch(err => {
            console.log(err);
          })
      },
      getUsersByProject: function(pdata){

         console.log("activeProjectData", pdata);
        this.preloader = true;
        this.activeProjectData = pdata || this.selected_project; 
        axios({ url: '/project/'+this.activeProjectData.id+'/users', method: 'GET' })
          .then(resp => {
            console.log("User", resp.data.success);
            this.desserts = resp.data.success;
               this.preloader = false;
          })
          .catch(err => {
              this.preloader = false;
            console.log(err);
          })
      },
     
      editItem (item) {
         console.log("item", item);
          this.editDate = item 
           this.e7 = item.project_ids.split(',')
            this.dialog = true
            this.userType =  item.user_code
            this.firstname = item.firstname
            this.lastname = item.lastname
            this.email = item.email
            this.contactNo = item.contactNo

            if(item.billing_access == 1){
              this.billingcheck = true
            }
             if(item.cont_camera_aceess == 1){
             this.constcameraaccess = true
            }

             if(item.material_access == 1){
             this.materialaccess = true
            }

             if(item.interior_editor_access == 1){
             this.interioreditaccess = true
            }

             if(item.interior_access == 1){
             this.interiorviewaccess = true
            }

            if(item.drone_editor_access == 1){
             this.droneeditaccess = true
            }

             if(item.drone_access == 1){
             this.droneviewaccess = true
            }

             if(item.linear_monitoring_access == 1){
             this.linearviewaccess = true
            }

             if(item.linear_monitoring_edit == 1){
             this.lineareditaccess = true
            }

             if(item.active == 1){
             this.activecheck = true
            }else{
             this.activecheck = false
            }
      
      },

      deleteItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.desserts.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save() {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
        } else {
          this.desserts.push(this.editedItem)
        }
        this.close()
      },
      sidebartoggle(){
        console.log("Toggle working");
      },
      UpdateUserInfo(){
              
              // console.log("Form Date ",this.params);

               axios({ url: '/users/'+this.editDate.id, data: this.params, method: 'PUT' })
          .then(resp => {
                this.updateresp = resp.data;
                console.log("update resp", this.updateresp);
             if(resp.data.success){

                    this.alert = true;
                    this.alertmessage = resp.data.success;
                    this.alertType = "success"
                    
                 }else{

                    this.alert = true;
                    this.alertmessage = resp.data.error;
                    this.alertType = "error"
                    
                 }
                     var self = this;
                      setTimeout(function(){ 
                         self.dialog = false;
                          self.alert = false;
                          self.getPorjects();
                      }, 4000);
                 
            
          })
          .catch(err => {
            console.log(err);
            this.alert = true;
            this.alertmessage = "Unauthorized action.";
            this.alertType = "error"
          })
      },
      getColor (calories) {
        if (calories == 0) return '#ec665cfc'
        else if (calories == 1) return '#26A65B'
        else return '#ff9800'
      },
      onValueChange: function (args) {

        if (args.startDate) {
                this.getUserLogs(args); 
            }

        },
         logs(item){

         

         this.logitem = item;

         this.getUserLogs('');

          console.log("User Logs",item);
      },
     gmttoisd(date){
        var dateUTC = new Date(date);
          var dateUTCdd = dateUTC.getTime() 
          var dateIST = new Date(dateUTCdd);
          //date shifting for IST timezone (+5 hours and 30 minutes)
          dateIST.setHours(dateIST.getHours() + 5); 
          dateIST.setMinutes(dateIST.getMinutes() + 30);

           var result =  new Date(dateIST).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
        
         return result;
     },
         getUserLogs: function (args) {
           this.preloader = true;
             this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
            console.log("user Logs Dataaa- ");
          this.current_cam = this.$store.state.currentCamera;
          var data = {};
           if (args) {

                console.log("args", args);

                  var  startd = new Date(this.chartdate.startDate).toISOString().slice(0, 10);
                  var  endd = new Date(this.chartdate.endDate).toISOString().slice(0, 10);

                  var start = startd+" 00:00:00";

                  var end = endd+" 23:59:59";

                console.log(start);
                console.log(end);
                data = {
                   userid : this.logitem.id,
                   start : start,
                   end : end,
                   opticvyu_user : this.userdata.opticvyu_user
                
                }
           }else{
                data = { 
                   userid : this.logitem.id,
                   opticvyu_user : this.userdata.opticvyu_user
                }
           }

        axios({ url: `/single-user-logs`, method: 'GET', params: data })
          .then(resp => {
             console.log("User Logs", resp);
              this.preloader = false;
             this.logstable = true;
             this.logsdesserts  = resp.data.success;
            
            })
          .catch(err => {
            console.log("user Logs error", err);
            this.preloader = false;
          })
      }
  }
};
</script>
<style>
.vhfix{
  max-height:91vh;
  overflow:auto;

} 
.myeditform .v-input--selection-controls {
    margin-top: 0px !important; 
   padding-top: 0px !important;
}
 .myeditform .v-card__text {
   padding-bottom: 0 !important; 
 }

 .myeditform  .v-data-footer, .vhfix .v-data-footer  {
    position: relative !important;
    bottom: 0;
    width: 100%  !important;;
    background: #fff;
    left: 0;
}
.closerlogs {
    position: relative;
    right: 10px;
    top: 21px;
    z-index: 99;
    width: 15px;
    float: right;
}
.uurlogin{
  position:relative;
  top:5px;
}
.tolp {
    top: -6px;
    position: relative;
}
</style>

