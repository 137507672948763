<template>
  <div>
    <Preloader v-if="preloader" />
    <div class="mobilerotedouter">
      <img :src="rotate" class="mobileroted detect">
    </div>
    <v-snackbar v-model="alertTip" top center timeout="15000" type="success">
      {{ alerttipmessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alertTip = false">
          {{ lang.Close }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="alert" top right timeout="3000" type="success">
      {{ alertmessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alert = false">
          {{ lang.Close }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-card>
      <v-card-text>
        <div class="pdfReportButton">
        <v-btn
            color="amber"
            @click="createReport()"
            small
            :disabled="isButtonDisabled"
            class="mr-2"
          >Create Report
          </v-btn>
          <span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >Select two videos for pdf report generation.</span
                >
              </v-tooltip>
            </span>
          </div>
        <v-row class="copmareFirstRow">
          <v-col class="compareVideo col-md-6 col-6">
            <v-select :items="zonesFirstRow" item-text="visit_date" item-value="id" :label="lang.VisitDate"
              class="light small comparisonDates" v-model="firstRowzoneid" dense x-small @change="selectFirstRowZone"
              outlined background-color="#c6c6c647"></v-select>
            <div v-if="!comparisonOne && compareDataFirstRow.length > 0">
              <v-row class="compareOneThumHeight">
                <div v-for="(slide, indx) in compareDataFirstRow" :key="indx + 100">
                  <v-vol class="col-md-3 col-sm-3 col-xs-12">
                    <img :src="slide.thumbnail_path == 'null'
                        ? vthumbnail
                        : slide.thumbnail_path
                      " v-if="slide.mediatype == 'Drone Video'" @click="playComparisonOneVideo(slide)"
                      style="height: 10vh;" />
                  </v-vol>
                </div>
              </v-row>
            </div>
            <video v-if="comparisonOne" @keydown.esc="comparisonOne = false" class="comparingVideo"
              id="comparison_video_one" controls="true" ref="custome_timelapsvideoCamparisonOne"></video>
              <v-icon
              v-if="comparisonOne"
            dark
            color="#000"
            @click="fullscreenexitcompareone()"
            class="exitButtonDroneVideoOne"
            large
          >
            mdi-fullscreen-exit
          </v-icon>
          </v-col>
          <v-col class="compare-maps col-md-6 col-6">
            <gmap-map map-type-id="satellite" :center="centerFirstRow" :zoom="zoomFirstRow" class="mapite" ref="mapRef">
              <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                @closeclick="infoWinOpen = false">
              </gmap-info-window>
              <gmap-marker :key="i" v-for="(m, i) in comparisonOneMarkers" :position="m.positionFirstRow"
                :clickable="true" :icon="comparisonOneMarkerr(m)" @click="comparisonOneVideoControllers(m)"></gmap-marker>
            </gmap-map>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row class="copmareSecondRow">
          <v-col class="compareVideo col-md-6 col-6 col-xs-6">
            <v-select :items="zonesSecondRow" item-text="visit_date" item-value="id" :label="lang.VisitDate"
              class="light small comparisonDates" v-model="secondRowzoneid" x-small dense @change="selectSecondRowZone"
              outlined background-color="#c6c6c647"></v-select>
            <div v-if="!comparisonTwo && compareDataSecondRow.length > 0">
              <v-row class="compareOneThumHeight">
                <div v-for="(slide, indx) in compareDataSecondRow" :key="indx + 100">
                  <v-vol class="col-md-3 col-sm-3 col-xs-12">
                    <img :src="slide.thumbnail_path == 'null'
                        ? vthumbnail
                        : slide.thumbnail_path
                      " @click="playComparisonTwoVideo(slide)" style="height: 10vh;" />
                  </v-vol>
                </div>
              </v-row>
            </div>
            <video v-if="comparisonTwo" @keydown.esc="comparisonTwo = false" class="comparingVideo"
              id="comparison_video_two" controls="true" ref="custome_timelapsvideoCamparisonTwo"></video>
              <v-icon v-if="comparisonTwo"
            dark
            color="#000"
            @click="fullscreenexitcomparetwo()"
            class="exitButtonDroneVideoTwo"
            large
          >
            mdi-fullscreen-exit
          </v-icon>
          </v-col>
          <v-col class="compare-maps col-md-6 col-6">
            <gmap-map map-type-id="satellite" :center="centerSecondRow" :zoom="zoomSecondRow" class="mapite" ref="mapRef">
              <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
                @closeclick="infoWinOpen = false">
              </gmap-info-window>
              <gmap-marker :key="i" v-for="(m, i) in comparisonTwoMarkers" :position="m.position" :clickable="true"
                :icon="comparisonTwoMarkerr(m)" @click="comparisonTwoVideoControllers(m)"></gmap-marker>
            </gmap-map>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
  
<script>
import axios from "axios";
import Preloader from "../../components/PreLoader";
//import vueDropzone from "vue2-dropzone";
import exifr from "exifr";
import vthumbnail from "../../assets/video_thumbnail.png";
import icon from "../../assets/Dot-Icon-Blue.png";
import iconactive from "../../assets/Dot-Icon-BlueActive.png";
import { locals } from "../../utils/locals";
import $ from "jquery";
import rotate from '../../assets/rotedmobilepsd.jpg';
export default {
  components: {
    Preloader
  },
  name: "CompareEvent",
  data() {
    return {
      rotate: rotate,
      preloader: false,
      files: [],
      dropOptions: {
        url: "https://api.opticvyu.com/api/project/gps/image-upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        paramName: "file",
        maxFilesize: 500, // MB
        acceptedFiles: ".jpeg,.jpg,.png",
        maxFiles: 4,
        chunkSize: 500, // Bytes
        thumbnailMethod: "contain",
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        autoProcessQueue: false,
        dictDefaultMessage:
          "Click to upload Images  <br />----------------- <br/>Drag & Drop"
      },
      thumbnailimage: "",
      pro_dir: "",
      projectid: "",
      userdata: {},
      imagesExtract: "",
      currentvisitId: "",
      visitdata: "",
      galleryview: true,
      uploadzone: false,
      visitDatepopup: false,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      images: [],
      loopcout: false,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      dats: [],
      center: {
        lat: 20.5937,
        lng: 78.9629
      },
      zoom: 5,
      markers: [],
      currentPlace: null,
      coi: 0,
      valid: true,
      currentMidx: null,
      fulllImage: false,
      liveimage: "",
      cimi: 0,
      alertmessage: "",
      alert: false,
      alertTip:false,
      alerttipmessage: "Select drone videos for comparison",
      refresh3: 0,
      sliderloader: true,
      latesttrue: false,
      down: false,
      mapToggle: false,
      selectedMedia: "Drone Video",
      mapMarkerActive: iconactive,
      mapMarker: icon,
      selectedKey: null,
      selectedMarker: null,
      activePosition: 0,
      vthumbnail: vthumbnail,
      infolist: [],
      Local: locals,
      lang: {},
      dialog: false,
      zonesFirstRow: {},
      zonesSecondRow: {},
      firstRowzoneid: 0,
      secondRowzoneid: 0,
      projid: "",
      zonename: "",
      items: [],
      comparisonOne: false,
      comparisonTwo: false,
      compareOneActivePosition: 0,
      comparisonOneMarkers: 0,
      compareTwoActivePosition: 0,
      comparisonTwoMarkers: 0,
      compareDataFirstRow: [],
      compareDataSecondRow: [],
      firstRowMarkers: [],
      secondRowMarkers: [],
      firstRowCoi: 0,
      secondRowCoi: 0,
      firstRowDats: [],
      secondRowDats: [],
      centerFirstRow: {
        lat: 20.5937,
        lng: 78.9629
      },
      centerSecondRow: {
        lat: 20.5937,
        lng: 78.9629
      },
      zoomFirstRow: 5,
      zoomSecondRow: 5,
      hasDroneVideoURL: "",
      droneVideoURLMainView: "",
      droneVideoIDMainView: "",
      rules: {
        required: (value) => !!value || "This field is required.",
        url: (value) => {
          // A simple regex to validate URLs
          const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
          return regex.test(value) || "Invalid URL.";
        },
      },
      isFormValid: false,
      firstCompareVideo20LatLong: [],
      secondCompareVideo20LatLong: [],
      firstCompareVideoPath: "",
      secondCompareVideoPath: "",
      updatedFirstCompareVideo20LatLong:[],
      compare_video_one_selected_date: "",
      compare_video_two_selected_date: "",
    };
  },
  mounted() {
    var selectleng = this.$cookies.get("currentLanguage");
    this.lang = locals[selectleng];
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
      this.useraccess = true;
    }
    // if (this.userdata.open_access == 1) {
    //   this.$router.push("/dashboard");
    // }
    let currentProject = JSON.parse(localStorage.getItem("currentProject"));
    //console.log("Current data get",this.$store.state.currentCamera)
    this.cam_prefix = this.$store.state.currentCamera.camera_prefix;
    this.projectid = currentProject.id;
    this.pro_dir = currentProject.pro_dir;
    //   this.initViewer();
    //   var _self = this;
    //   document.body.addEventListener("keyup", e => {
    //     if (e.keyCode === 27) {
    //       _self.fullscreenexit();
    //       this.mediatypeset("Drone Video");
    //     }
    //   });
    this.getVisitDatesFirstComparisonRow();
    this.getVisitDatesSecondRowComparison();
    this.mediatypesetCompare("Drone Video");
    this.alertTip = true;
    // this.alertmessage = "Select drone videos for comparison";
  },
  created() {
    this.$root.$refs.openDroneCompareVideoDialog = this;
  },
  watch: {
    "$store.state.currentvisitId": function () {
      this.preloader = true;
      this.latesttrue = false;
      var _self = this;
      setTimeout(() => {
        _self.currentvisitId = _self.$store.state.currentvisitId;
        // this.$refs.myVueDropzone.removeAllFiles();
        _self.initViewer();
        _self.sliderloader = false;

        _self.preloader = false;
      }, 5000);
    },
    $route: function () {
      //console.log(to);
      var hash = window.location.hash;
      console.log(hash);
      var result = hash.substring(1);
      this.openbyDrop(result);
    }
  },
  computed: {
  isButtonDisabled() {
    return this.firstCompareVideo20LatLong.length == 0 || this.secondCompareVideo20LatLong.length == 0;
  }
},
  methods: {
    initViewer() {
      //   this.preloader = true;
      console.log("Page Load", this.currentvisitId);
      this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

      console.log("this.visitdata", this.visitdata);
      this.getImages();
    },
    sendingEvent(file, xhr, formData) {
      console.log("d", file);
      // formData.append("project_id", "2");
      this.visitdata = JSON.parse(localStorage.getItem("currentvisitD"));

      console.log("xhr", xhr);

      formData.append("latitude", this.imagesExtract.latitude);

      formData.append("longitude", this.imagesExtract.longitude);

      formData.append("gps_date_stamp", this.imagesExtract.GPSDateStamp);

      formData.append("gps_time_stamp", this.imagesExtract.GPSTimeStamp);

      formData.append("project_id", this.projectid);
      formData.append("proj_dir", this.pro_dir);
      formData.append("thumbnail", this.thumbnailimage);
      formData.append("user_id", this.userdata.id);
      formData.append("visit_date", this.visitdata.visit_date);
      formData.append("visit_id", this.visitdata.id);

      console.log("formData", formData);
    },
    async thumbnail(file, dataUrl) {
      this.thumbnailimage = dataUrl;
      var s = await exifr.parse(file);
      this.imagesExtract = s;
      console.log(
        s.latitude,
        s.longitude,
        s.GPSDateStamp,
        s.GPSTimeStamp,
        s.Make,
        s.Model,
        s.Software,
        file.name
      );

      // console.log("this.thumbnailimage g", this.thumbnailimage);

      this.$refs.myVueDropzone.processQueue();
    },
    uploadzoneAction() {
      this.galleryview = false;
      this.uploadzone = true;
    },
    galleryviewAction() {
      this.getImages();
      this.galleryview = true;
      this.uploadzone = false;
    },
    //   addvisitDate() {
    //     this.visitDatepopup = !this.visitDatepopup;
    //   },
    getImages() {
      this.markers = [];
      this.refresh3++;
      console.log("currentvisitId", this.currentvisitId);
      this.marker = [];
      //   this.loopcout = false;
      axios({
        url: "/project/" + this.projectid + "/gps/get_images/" + this.visitdata.id,
        method: "GET"
      })
        .then(resp => {
          console.log("visit data resp", resp);
          if (resp.data.success.length > 0) {
            console.log(" if in visit data resp", resp);
            var _self = this;
            this.dats = resp.data.success.filter(function (item) {
              console.log(
                "item.mediatype",
                item.mediatype,
                _self.selectedMedia
              );

              return item.mediatype == _self.selectedMedia;
            });
            if (this.selectedMedia == "Drone Video") {
              this.maplocationsviwer(this.dats[0]);
            }
            console.log("visit data resp[0]", this.dats[0]);
            this.images = this.dats;
            this.coi = this.dats.length;

            var n = 0;
            /// var sa = '';
            this.dats.forEach((i, index) => {
              console.log("==>", i.latitude, i.longitude);

              if (i.latitude && i.longitude) {
                this.markers[n] = {
                  position: {
                    lat: parseFloat(i.latitude),
                    lng: parseFloat(i.longitude)
                  },
                  infoText:
                    '<a style="text-align:center" href="#' +
                    index +
                    '" > Open Image </a>'
                };
              }

              n++;
              var _self = this;
              if (this.coi == n) {
                setTimeout(() => {
                  _self.loopcout = true;
                  var lt = parseFloat(this.dats[0].latitude);
                  var lng = parseFloat(this.dats[0].longitude);
                  console.log("this.markers", _self.markers);
                  _self.center = {
                    lat: lt,
                    lng: lng
                  };
                  _self.zoom = 15;
                  _self.latesttrue = true;
                  _self.sliderloader = false;
                }, 2000);
              }
            });
          } else {
            console.log("elsem", resp);
            this.sliderloader = false;
            this.preloader = false;
            // this.alert = true;
            // this.alertmessage = "Drone Videos Not Available.";
          }
        })
        .catch(err => {
          console.log("Getting image Error ", err);
        });
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
      fullscreenexitcompareone() {
        this.comparisonOne = false;
      },
      fullscreenexitcomparetwo() {
        this.comparisonTwo = false;
      },
    //   fullpanaromaImage(d, index) {
    //     this.fulllImage = true;
    //     this.mapToggle = true;
    //     this.cimi = index;
    //     if (this.selectedMedia == "Drone Video") {
    //       this.maplocationsviwer(d);
    //       this.maplocationsInfo(d);
    //       var _self = this;
    //       setTimeout(() => {
    //         _self.$refs.custome_timelapsvideo.src = d.image_path;
    //       }, 500);
    //     } else {
    //       console.log("fullpanaromaImage", index);
    //       this.liveimage = d.image_path;
    //     }
    //   },
    openbyDrop(index) {
      this.fulllImage = true;
      this.mapToggle = true;
      this.cimi = index;

      this.liveimage = this.images[index].image_path;
    },
    preImage() {
      var dataindex = this.cimi - 1;

      this.cimi = dataindex;

      console.log("fullpanaromaImage preImage", dataindex);

      var data = this.images[dataindex];

      this.liveimage = data.image_path;
    },
    nextImage() {
      var dataindex = this.cimi + 1;

      this.cimi = dataindex;

      console.log("fullpanaromaImage nextImage", dataindex);

      var data = this.images[dataindex];
      this.liveimage = data.image_path;
    },
    addVisitDate() {
      console.log("this  dater", this.picker);

      axios({
        url: "/project/" + this.projectid + "/gps/new_visit_date",
        method: "post",
        data: {
          project_id: this.projectid,
          visit_date: this.picker,
          createdby: this.userdata.id
        }
      })
        .then(resp => {
          console.log(resp);
          this.alert = true;
          if (resp.data.error) {
            this.alertmessage = resp.data.error.message;
          } else {
            this.alertmessage = "Visit date added Successfully";
            this.visitDatepopup = false;
            this.$router.go();
          }
        })
        .catch(err => {
          console.log(err);
          this.alert = true;
          this.alertmessage = "Visit date adding error";
          this.alertType = "error";
          this.preloader = false;
          this.showprogrss = false;
        });
    },
    downUp() {
      this.down = !this.down;
    },
    //   mediatypeset(c) {
    //     console.log(c);
    //     this.latesttrue = false;

    //     this.selectedMedia = c;
    //     var _self = this;
    //     setTimeout(() => {
    //       _self.getImages();
    //     }, 1000);
    //   },
    videocontrollers(m, index) {
      if (this.selectedMedia == "Drone Video") {
        var theVideo = document.getElementById("cspd_video");
        // var vid_currentTime = theVideo.currentTime;
        theVideo.currentTime = m.time;
      } else {
        this.fulllImage = true;
        this.cimi = index;
        this.mapToggle = true;

        this.liveimage = this.images[index].image_path;
      }
    },
    maplocationsInfo(d) {
      console.log("d", d);
      axios({
        url: "/project/gps/drone-info",
        method: "get",
        params: {
          mediaid: d.id,
          project_id: this.projectid
        }
      })
        .then(resp => {
          console.log(resp.data.success);
          this.infolist = resp.data.success;
          this.hasDroneVideoURL = d.drone_video_url;
        })
        .catch(err => {
          console.log(err);
        });
    },
    maplocationsviwer(d) {
      this.loopcout = false;
      var _self = this;
      $.getJSON(d.gpsfile, function (mydata) {
        _self.coi = mydata.length;

        var n = 0;
        _self.markers = [];
        mydata.forEach((i, index) => {

          if (i.lat && i.long) {
            _self.markers[n] = {
              position: {
                lat: parseFloat(i.long),
                lng: parseFloat(i.lat)
              },
              infoText:
                '<a style="text-align:center" href="#' +
                index +
                '" > ' +
                i.id +
                " </a>",
              time: i.start
            };
          }
          //console.log("n", n);
          n++;

          //  console.log("this.coi == n+1", _self.coi, n);
          if (_self.coi == n) {
            setTimeout(() => {
              _self.loopcout = true;
              var lt = parseFloat(mydata[0].long);
              var lng = parseFloat(mydata[0].lat);
              //  console.log("this.markers in", _self.markers);
              _self.center = {
                lat: lt,
                lng: lng
              };
              _self.zoom = 17;
            }, 1000);
          }
        });
      });

      _self.checkvideoloop();
    },
    checkvideoloop() {
      //
      // theVideo.currentTime = vid_currentTime - 5;

      //  this.getMarkers()
      var _self = this;
      //  var n = 1;
      setInterval(() => {
        var theVideo = document.getElementById("cspd_video");
        if (theVideo !== null) var vid_currentTime = theVideo.currentTime;
        // console.log("vid_currentTime", vid_currentTime)
        _self.activePosition = Math.round(vid_currentTime);
        //    n++;
      }, 1000);
    },
    markerr(m, i) {
      if (this.selectedMedia == "Drone Video") {
        if (
          this.activePosition >= Math.round(m.time) &&
          this.activePosition - 2 <= Math.round(m.time)
        ) {
          return this.mapMarkerActive;
        } else {
          return this.mapMarker;
        }
      } else {
        if (this.cimi == i) {
          return this.mapMarkerActive;
        } else {
          return this.mapMarker;
        }
      }
    },
    getVisitDates() {
      // Moduletype variable store value of marial, interior or constructuion cameras.
      axios({
        url: "/project/" + this.projectid + "/gps/get_visit_date/",
        method: "GET"
      })
        .then(resp => {
          this.preloader = false;
          this.zones = resp.data.success;
          if (this.zones.length == 0) {
            localStorage.setItem("currentvisitDate", "");
          } else {
            localStorage.setItem(
              "currentvisitDate",
              JSON.stringify(resp.data.success[0])
            );
            this.zonename = resp.data.success[0].visit_date;
          }
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        });
    },
    storeVisitData(id) {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      axios({
        url: "/project/" + currentProject.id + "/gps/get_visit/" + id,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.success) {
            localStorage.setItem(
              "currentvisitDate",
              JSON.stringify(resp.data.success)
            );
          } else {
            localStorage.setItem("currentvisitDate", JSON.stringify({}));
          }
        })
        .catch(err => {
          console.log("Error in current visit data set", err);
        });
    },
    /*--------------------------comparison---------------------------------*/
    comparisonDialogModel() {
      this.dialog = false;
      this.mediatypeset("Drone Video");
    },
    mediatypesetCompare(c) {
      this.latesttrue = false;
      this.selectedMedia = c;
      var _self = this;
      setTimeout(() => {
        _self.getComparisonData();
      }, 1000);
    },
    getComparisonData() {
      // this.refresh3++;
      console.log("currentvisitId", this.currentvisitId);
      // this.marker = [];
      //   this.loopcout = false;
      axios({
        url:
          "/project/" + this.projectid + "/gps/get_images/" + this.visitdata.id,
        method: "GET"
      })
        .then(resp => {
          console.log("visit data resp", resp);
          if (resp.data.success.length > 0) {
            console.log(" if in visit data resp", resp);
            var _self = this;
            this.dats = resp.data.success.filter(function (item) {
              console.log(
                "item.mediatype",
                item.mediatype,
                _self.selectedMedia
              );

              return item.mediatype == _self.selectedMedia;
            });
            console.log("visit data resp", this.dats);
            this.images = this.dats;

            this.coi = this.dats.length;

            var n = 0;
            /// var sa = '';
            this.dats.forEach((i, index) => {
              console.log("==>", i.latitude, i.longitude);

              if (i.latitude && i.longitude) {
                this.markersCompare[n] = {
                  position: {
                    lat: parseFloat(i.latitude),
                    lng: parseFloat(i.longitude)
                  },
                  infoText:
                    '<a style="text-align:center" href="#' +
                    index +
                    '" > Open Image </a>'
                };
              }

              n++;
              var _self = this;
              if (this.coi == n) {
                setTimeout(() => {
                  _self.loopcout = true;
                  var lt = parseFloat(this.dats[0].latitude);
                  var lng = parseFloat(this.dats[0].longitude);
                  console.log("this.markersCompare", _self.markersCompare);
                  _self.center = {
                    lat: lt,
                    lng: lng
                  };
                  _self.zoom = 15;
                  _self.latesttrue = true;
                  _self.sliderloader = false;
                }, 2000);
              }
            });
          } else {
            console.log("elsem", resp);
            this.sliderloader = false;
            this.preloader = false;
            this.alert = true;
            this.alertmessage = "Drone Videos Not Available.";
          }
        })
        .catch(err => {
          console.log("Getting image Error ", err);
        });
    },
    comparisonOneMarkerr(m) {
      if (
        this.compareOneActivePosition >= Math.round(m.time) &&
        this.compareOneActivePosition - 6 <= Math.round(m.time)
      ) {
        return this.mapMarkerActive;
      } else {
        return this.mapMarker;
      }
    },
    comparisonTwoMarkerr(m) {
      if (
        this.compareTwoActivePosition >= Math.round(m.time) &&
        this.compareTwoActivePosition - 6 <= Math.round(m.time)
      ) {
        return this.mapMarkerActive;
      } else {
        return this.mapMarker;
      }
    },
    //   enableCompareDialog() {
    //     this.getVisitDatesFirstComparisonRow();
    //     this.getVisitDatesSecondRowComparison();
    //     this.mediatypesetCompare("Drone Video");
    //     this.dialog = true;
    //   },
    getVisitDatesFirstComparisonRow() {
      this.zonesFirstRow = {};
      axios({
        url: "/project/" + this.projectid + "/gps/get_visit_date/",
        method: "GET"
      })
        .then(resp => {
          this.preloader = false;
          this.zonesFirstRow = resp.data.success;
          if (this.zonesFirstRow.length == 0) {
            this.selectFirstRowZone(0);
          } else {
            this.selectFirstRowZone(resp.data.success[0].id);
            this.firstRowzoneid = resp.data.success[0].id;
          }
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        });
    },
    getVisitDatesSecondRowComparison() {
      this.zonesSecondRow = {};
      axios({
        url: "/project/" + this.projectid + "/gps/get_visit_date/",
        method: "GET"
      })
        .then(resp => {
          this.preloader = false;
          this.zonesSecondRow = resp.data.success;
          if (this.zonesSecondRow.length == 0) {
            this.selectSecondRowZone(0);
            // localStorage.setItem('currentvisitD','');
          } else {
            this.selectSecondRowZone(resp.data.success[0].id);
            //  localStorage.setItem('currentvisitD', JSON.stringify(resp.data.success[0]))
            this.secondRowzoneid = resp.data.success[0].id;
          }
        })
        .catch(err => {
          console.log(err);
          this.preloader = false;
        });
    },
    selectFirstRowZone(event) {
      this.comparisonOne = false;
      var _self = this;
      this.$store
        .dispatch("setVistId", event)
        .then(() => _self.storeVisitDataFirstComparisonRow(event))
        .catch(err => console.log(err));
    },
    selectSecondRowZone(event) {
      this.comparisonTwo = false;
      this.$store
        .dispatch("setVistId", event)
        .then(() => this.storeVisitDataSecondRowComparison(event))
        .catch(err => console.log(err));
    },
    storeVisitDataFirstComparisonRow(id) {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      axios({
        url: "/project/" + currentProject.id + "/gps/get_visit/" + id,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.success) {
            var visitFirstRowId = resp.data.success.id;
            this.compare_video_one_selected_date = resp.data.success.visit_date;
            this.getVideoFirstRow(visitFirstRowId);
          } else {
            this.alert = true;
            this.alertmessage = "Data not available.";
          }
        })
        .catch(err => {
          console.log(
            "Error in current visit data set for first comparison row",
            err
          );
        });
    },
    storeVisitDataSecondRowComparison(id) {
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      axios({
        url: "/project/" + currentProject.id + "/gps/get_visit/" + id,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.success) {
            var visitSecondRowId = resp.data.success.id;
            this.compare_video_two_selected_date = resp.data.success.visit_date;
            this.getVideoSecondRow(visitSecondRowId);
          } else {
            this.alert = true;
            this.alertmessage = "Data not available.";
          }
        })
        .catch(err => {
          console.log(
            "Error in current visit data set for second comparison row",
            err
          );
        });
    },
    getVideoFirstRow(visitFirstRowId) {
      this.firstRowMarkers = [];
      this.compareDataFirstRow = [];
      axios({
        url:
          "/project/" + this.projectid + "/gps/get_images/" + visitFirstRowId,
        method: "GET"
      })
        .then(resp => {
          // if (resp.data.success.length > 0) {
            var _self = this;
            this.firstRowDats = resp.data.success.filter(function (item) {
              return item.mediatype == _self.selectedMedia;
            });
            if (this.firstRowDats.length > 0) {
              this.compareDataFirstRow = this.firstRowDats;

              this.firstRowCoi = this.firstRowDats.length;

              var n = 0;

              this.firstRowDats.forEach((i, index) => {
                console.log("==>", i.latitude, i.longitude);

                if (i.latitude && i.longitude) {
                  this.firstRowMarkers[n] = {
                    positionFirstRow: {
                      lat: parseFloat(i.latitude),
                      lng: parseFloat(i.longitude)
                    },
                    infoText:
                      '<a style="text-align:center" href="#' +
                      index +
                      '" > Open Image </a>'
                  };
                }

                n++;
                var _self = this;
                if (this.firstRowCoi == n) {
                  setTimeout(() => {
                    _self.loopcout = true;
                    var lt = parseFloat(this.firstRowDats[0].latitude);
                    var lng = parseFloat(this.firstRowDats[0].longitude);
                    console.log("this.firstRowMarkers", _self.firstRowMarkers);
                    _self.centerFirstRow = {
                      lat: lt,
                      lng: lng
                    };
                    _self.zoomFirstRow = 15;
                    _self.latesttrue = true;
                    _self.sliderloader = false;
                  }, 2000);
                }
              });
            // } else {
            //   this.alert = true;
            //   this.alertmessage = "Drone video not available.";
            // }
          } else {
            console.log("elsem", resp);
            this.sliderloader = false;
            this.preloader = false;
            this.alert = true;
            this.alertmessage = "Data not available.";
          }
        })
        .catch(err => {
          console.log("Getting image Error ", err);
        });
    },
    getVideoSecondRow(visitSecondRowId) {
      this.secondRowMarkers = [];
      this.compareDataSecondRow = [];
      axios({
        url:
          "/project/" + this.projectid + "/gps/get_images/" + visitSecondRowId,
        method: "GET"
      })
        .then(resp => {
          if (resp.data.success.length > 0) {
            var _self = this;
            this.secondRowDats = resp.data.success.filter(function (item) {
              return item.mediatype == _self.selectedMedia;
            });
            this.compareDataSecondRow = this.secondRowDats;

            this.secondRowCoi = this.secondRowDats.length;

            var n = 0;

            this.secondRowDats.forEach((i, index) => {
              console.log("==>", i.latitude, i.longitude);

              if (i.latitude && i.longitude) {
                this.secondRowMarkers[n] = {
                  position: {
                    lat: parseFloat(i.latitude),
                    lng: parseFloat(i.longitude)
                  },
                  infoText:
                    '<a style="text-align:center" href="#' +
                    index +
                    '" > Open Image </a>'
                };
              }

              n++;
              var _self = this;
              if (this.secondRowCoi == n) {
                setTimeout(() => {
                  _self.loopcout = true;
                  var lt = parseFloat(this.secondRowDats[0].latitude);
                  var lng = parseFloat(this.secondRowDats[0].longitude);
                  console.log("this.secondRowMarkers", _self.secondRowMarkers);
                  _self.centerSecondRow = {
                    lat: lt,
                    lng: lng
                  };
                  _self.zoomSecondRow = 15;
                  _self.latesttrue = true;
                  _self.sliderloader = false;
                }, 2000);
              }
            });
          } else {
            console.log("elsem", resp);
            this.sliderloader = false;
            this.preloader = false;
            this.alert = true;
            this.alertmessage = "Data not available.";
          }
        })
        .catch(err => {
          console.log("Getting image Error ", err);
        });
    },
    playComparisonOneVideo(d) {
      this.comparisonOne = true;
      this.comparisonOneMapLocationsViwer(d);
      this.comparisonOneMapLocationsInfo(d);
      this.firstCompareVideoPath = d.image_path;
      var _self = this;
      setTimeout(() => {
        _self.$refs.custome_timelapsvideoCamparisonOne.src = d.image_path;
      }, 500);
    },
    playComparisonTwoVideo(d) {
      this.comparisonTwo = true;
      this.comparisonTwoMapLocationsViwer(d);
      this.comparisonTwoMapLocationsInfo(d);
      this.secondCompareVideoPath = d.image_path;
      var _self = this;
      setTimeout(() => {
        _self.$refs.custome_timelapsvideoCamparisonTwo.src = d.image_path;
      }, 500);
    },
    comparisonOneVideoControllers(m) {
      var theVideoOne = document.getElementById("comparison_video_one");
      // var vid_currentTime = theVideo.currentTime;
      theVideoOne.currentTime = m.time;
    },
    comparisonOneMapLocationsInfo(d) {
      axios({
        url: "/project/gps/drone-info",
        method: "get",
        params: {
          mediaid: d.id,
          project_id: this.projectid
        }
      })
        .then(resp => {
          console.log(resp.data.success);
          this.infolist = resp.data.success;
        })
        .catch(err => {
          console.log(err);
        });
    },
    comparisonOneMapLocationsViwer(d) {
      console.log(d, "d");
      this.loopcout = false;
      var _self = this;
      $.getJSON(d.gpsfile, function (mydata) {
        _self.firstRowCoi = mydata.length;
        var differnceVal = Math.round(mydata.length/20);
        const everyFifthPair = mydata.filter((_, index) => index % differnceVal === 0);
        
        const latLongs = everyFifthPair.slice(0, 20).map(object => {
          console.log(typeof object.lat, "object");
          console.log(object, "object");
          if (typeof object.lat === 'number') {
            object.lat = object.lat.toString();
          } 
            const [latIntegerPart, latDecimalPart] = object.lat.split('.');
            console.log(object.lat, "object");
            const truncatedLatDecimalPart = latDecimalPart.substring(0, 3);
            const truncatedLat = parseFloat(latIntegerPart + '.' + truncatedLatDecimalPart);
            const lat = truncatedLat;
            if (typeof object.long === 'number') {
              object.long = object.long.toString();
            } 
            const [longIntegerPart, longDecimalPart] = object.long.split('.');
            const truncatedLongDecimalPart = longDecimalPart.substring(0, 3);
            const truncatedLong = parseFloat(longIntegerPart + '.' + truncatedLongDecimalPart);
            const long = truncatedLong;
            const screenShotStartTime = object.start;
            const screenShotEndTime = object.end;
            return { lat, long, screenShotStartTime, screenShotEndTime};
          });

          // Remove duplicates
          _self.firstCompareVideo20LatLong= latLongs.reduce((unique, location) => {
          if (!unique.some(uniqueLocation => uniqueLocation.lat === location.lat && uniqueLocation.long === location.long)) {
            unique.push(location);
          }
          return unique;
        }, []);

        var n = 0;
        _self.comparisonOneMarkers = [];
        mydata.forEach((i, index) => {
          if (i.lat && i.long) {
            _self.comparisonOneMarkers[n] = {
              positionFirstRow: {
                lat: parseFloat(i.long),
                lng: parseFloat(i.lat)
              },
              infoText:
                '<a style="text-align:center" href="#' +
                index +
                '" > ' +
                i.id +
                " </a>",
              time: i.start
            };
          }

          n++;
          if (_self.firstRowCoi == n) {
            setTimeout(() => {
              _self.loopcout = true;
              var lt = parseFloat(mydata[0].long);
              var lng = parseFloat(mydata[0].lat);
              _self.centerFirstRow = {
                lat: lt,
                lng: lng
              };
              _self.zoomFirstRow = 17;
            }, 1000);
          }
        });
      });

      _self.comparisonOneCheckVideoLoop();
    },
    comparisonOneCheckVideoLoop() {
      var _self = this;
      setInterval(() => {
        var compareVideoOne = document.getElementById("comparison_video_one");
        if (compareVideoOne !== null)
          var compare_vid_one_currentTime = compareVideoOne.currentTime;
        _self.compareOneActivePosition = Math.round(
          compare_vid_one_currentTime
        );
      }, 1000);
    },
    comparisonTwoVideoControllers(m) {
      var theVideoTwo = document.getElementById("comparison_video_two");
      theVideoTwo.currentTime = m.time;
    },
    comparisonTwoMapLocationsInfo(d) {
      axios({
        url: "/project/gps/drone-info",
        method: "get",
        params: {
          mediaid: d.id,
          project_id: this.projectid
        }
      })
        .then(resp => {
          console.log(resp.data.success);
          this.infolist = resp.data.success;
        })
        .catch(err => {
          console.log(err);
        });
    },
    comparisonTwoMapLocationsViwer(d) {
      this.loopcout = false;
      var _self = this;
      $.getJSON(d.gpsfile, function (mydata) {
        const array2 = mydata.map(object => {
          if (typeof object.lat === 'number') {
            object.lat = object.lat.toString();
          } 
          const [latIntegerPart, latDecimalPart] = object.lat.split('.');
          const truncatedLatDecimalPart = latDecimalPart.substring(0, 3);
          const truncatedLat = parseFloat(latIntegerPart + '.' + truncatedLatDecimalPart);
          const lat = truncatedLat;
          if (typeof object.long === 'number') {
            object.long = object.long.toString();
          } 
          const [longIntegerPart, longDecimalPart] = object.long.split('.');
          const truncatedLongDecimalPart = longDecimalPart.substring(0, 3);
          const truncatedLong = parseFloat(longIntegerPart + '.' + truncatedLongDecimalPart);
          const long = truncatedLong;
          const screenShotStartTime = object.start;
            const screenShotEndTime = object.end;
          return { lat, long, screenShotStartTime, screenShotEndTime };
        });
        
        // Extract matching lat/longs
        let matchingLatLongs = _self.firstCompareVideo20LatLong.filter(firstLocation => array2.some(secondLocation =>
            firstLocation.lat === secondLocation.lat && firstLocation.long === secondLocation.long
          )
        );
        // Remove duplicates
        _self.secondCompareVideo20LatLong = matchingLatLongs.reduce((unique, location) => {
          if (!unique.some(uniqueLocation => uniqueLocation.lat === location.lat && uniqueLocation.long === location.long)) {
            unique.push(location);
          }
          return unique;
        }, []);
        // Remove non-matching lat/longs from the first array
        _self.updatedFirstCompareVideo20LatLong = _self.firstCompareVideo20LatLong.filter(firstLocation =>
        _self.secondCompareVideo20LatLong.some(secondLocation =>
            firstLocation.lat === secondLocation.lat && firstLocation.long === secondLocation.long
          )
        );
        console.log(_self.secondCompareVideo20LatLong,"_self.secondCompareVideo20LatLong");
        console.log(_self.updatedFirstCompareVideo20LatLong,"_self_updatedFirstCompareVideo20LatLong");

        _self.secondRowCoi = mydata.length;

        var n = 0;
        _self.comparisonTwoMarkers = [];
        mydata.forEach((i, index) => {
          if (i.lat && i.long) {
            _self.comparisonTwoMarkers[n] = {
              position: {
                lat: parseFloat(i.long),
                lng: parseFloat(i.lat)
              },
              infoText:
                '<a style="text-align:center" href="#' +
                index +
                '" > ' +
                i.id +
                " </a>",
              time: i.start
            };
          }

          n++;
          if (_self.secondRowCoi == n) {
            setTimeout(() => {
              _self.loopcout = true;
              var lt = parseFloat(mydata[0].long);
              var lng = parseFloat(mydata[0].lat);
              _self.centerSecondRow = {
                lat: lt,
                lng: lng
              };
              _self.zoomSecondRow = 17;
            }, 1000);
          }
        });
      });

      _self.comparisonTwoCheckVideoLoop();
    },
    comparisonTwoCheckVideoLoop() {
      var _self = this;
      setInterval(() => {
        var compareVideoTwo = document.getElementById("comparison_video_two");
        if (compareVideoTwo !== null)
          var compare_vid_two_currentTime = compareVideoTwo.currentTime;
        _self.compareTwoActivePosition = Math.round(
          compare_vid_two_currentTime
        );
      }, 1000);
    },
    createReport(){
      let currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.alert = true;
      this.alertmessage = "Report generation in progress & will be emailed to you soon.";
      axios({
        url: "https://www.opticvyu.com/api/getDroneVideoPDF",
        method: "post",
        data: {
          firstVideoData: this.updatedFirstCompareVideo20LatLong,
          secondVideoData: this.secondCompareVideo20LatLong,
          firstVideoPath: this.firstCompareVideoPath,
          secondVideoPath: this.secondCompareVideoPath,
          project_id: this.projectid,
          user_email: this.userdata.email,
          compare_video_one_date: this.compare_video_one_selected_date,
          compare_video_two_date: this.compare_video_two_selected_date,
          user_id: this.userdata.id,
          project_lat: currentProject.latitude,
          project_long: currentProject.longitude
        }
      })
        .then(resp => {
          if(resp){
            console.log("report created successfully.");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
  
<style>
.ttile {
  position: relative;
  top: 5px;
  font-size: 18px;
  font-weight: bold;
  padding-left: 15px;
}

.mapite.min {
  position: fixed !important;
  width: 150px;
  z-index: 99999;
  height: 150px;
  top: 10px;
  left: 10px;
  transition: 0.5s;
}

.mapite.min:hover {
  top: 0px;
  left: 0px;
  opacity: 1;
  width: 700px;
  z-index: 99999;
  height: 100vh;
  transition: 0.5s;
}

.media.item {
  position: fixed;
  z-index: 1;
  top: 52px;
  left: 200px;
}

.mediatypees.active {
  /* background: #000 !important;
      color: #fff;
      border: 2px solid #fff;
      box-sizing: border-box; */
  font-weight: 900;
}

.fullviewbox.fifty {
  width: 50%;
  right: 0;
  left: 50%;
  background: #ffffff;
}

.mapite.min.fifty {
  width: 50%;
  left: 0;
  height: 100%;
  top: 0;
}

.mediatypees strong {
  position: relative;
  right: -10px;
  border: 1px solid;
  display: inline;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 19px;
}

.info-items-user-view {
  position: absolute;
  left: 10px;
  top: 20px;
  z-index: 1;
}

span.info_title_user_view {
  color: #ffc30e;
  background: #5f5f5fb3;
  font-weight: 600;
  font-size: 31px;
  padding: 3px 6px;
}

span.info_description_user_view {
  color: #fff;
  background: #5f5f5fb3;
  font-size: 14px;
  display: block;
  padding: 3px 6px;
}

.downloadButton {
  background: #444444b0;

}

.videostylez {
  margin: auto;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.right-next-image {
  right: 16px;
}

.vue-map-container {
  position: absolute !important;
}

.mapite.min {
  position: fixed !important;
  width: 150px;
  z-index: 99999;
  height: 150px;
  top: 10px;
  left: 10px;
  transition: 0.5s;
}

/*******Drone Video Comparison Style ********/
.compareVideo.video {
  width: 50%;
  z-index: 9999;
  background: #ffffff;
  /* height: 94vh; */
}

.compare-maps .vue-map-container {
  position: relative !important;
  /* max-width: 645px; */
  height: 47vh;
}

.compare-maps .mapite.min {
  position: fixed !important;
  width: 150px;
  z-index: 99999;
  height: 150px;
  top: 10px;
  left: 10px;
  transition: 0.5s;
}

/* .compareButton{
      position: absolute;
      z-index: 99;
      bottom: 101%;
      right: 321px;
  } */
.comparingVideo {
  height: 47vh;
}

.copmareFirstRow {
  height: 50vh;
  margin-bottom: 0px;
  margin-top: 1px;
}

.copmareSecondRow {
  height: 50vh;
  margin-top: 0px;
}

.comparisonDates {
  max-width: 142px;
  z-index: 1;
  position: absolute;
  /* opacity: 43%; */
  right: 51%;
}

.comparisonDialog .v-card {
  height: 99vh;
}

.compareExitButton {
  z-index: 999;
  float: right;
  top: 13px;
  right: 0px;
  position: fixed !important;
  background: #ffc107;
}

.addUrlIconSpanMainView i {
  font-size: 16px !important;
  color: #fec306 !important;
}

.addUrlIconSpanMainView {
  position: absolute;
  z-index: 1;
  top: -2px;
  left: 1px;
}

.compareOneThumHeight {
  margin-top: 70px;
  margin-top: 70px;
  overflow: auto;
  max-height: 315px;
}

.exitButtonDroneVideoOne {
  z-index: 1;
  top: 67px;
  left: 24px;
  position: absolute !important;
  background: #ffc107;
  border-radius: 50%;
}

.exitButtonDroneVideoTwo {
  z-index: 1;
  margin: 11px auto;
  left: 24px;
  position: absolute !important;
  background: #ffc107;
  border-radius: 50%;
}

.pdfReportButton{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  