<template>
    <div>
      <Preloader v-if="preloader" />
      <v-row>
        <v-col cols="12" md="9">
          <v-card class="pa-5">
            <v-card-title class="vtourHeading"> VIRTUAL TOUR </v-card-title>
            <v-btn class="mr-4" @click="virtualTour" color="mylightbutton">
                GO TO 360 TOUR
              </v-btn>
              <v-btn class="mr-4" color="mylightbutton" disabled>
                GO TO 3D MODEL
              </v-btn>
              <v-btn class="mr-4" color="mylightbutton" disabled>
                GO TO SCAN-TO-BIM
              </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
//   import axios from "axios";
//   import { log } from "fabric/fabric-impl";
import Preloader from "../../components/PreLoader";
//   import Sidebar from "../../../components/SettingSidebar";
  import { locals } from "../../utils/locals";
  export default {
    components: {
      Preloader,
    //   Sidebar
    },
    name: "Adduser",
    data() {
      return {
        valid: true,
        firstname: "openaccess",
        lastname: "",
        email: "",
        preloader: false,
        alert: false,
        alertmessage: "",
        alertType: "",
        e7: [],
  
        usertype: "1",
        projects: {},
        useraccess: false,
        userinfo: false,
        dialog: false,
        ustatus: "",
        param_up: {},
        snackbar: false,
        snackbarError: false,
        text: "Something went wrong. Please reload and try again.",
        snackbarErrorText: "Please check atleast one check box.",
        token: "",
        baseURL: "",
        
      };
    },
    mounted() {
      var selectleng = this.$cookies.get("currentLanguage");
      this.lang = locals[selectleng];
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      if (this.userdata.user_code == 2 || this.userdata.user_code == 0) {
        this.useraccess = true;
      } else {
        this.$router.push("/");
      }
      if (this.userdata.free_access == 0) {
        this.useraccess = true;
      } else {
        console.log("Redirect to dashboard");
      }
      
    },
    methods: {
        virtualTour(){
            this.token = localStorage.getItem("token");
            this.baseURL = "https://3da.opticvyu.com/index.html?token="+this.token+"&pid="+this.currentProject.id;
            window.open(this.baseURL, '_blank');
        }
    }
  };
  </script>
  <style>
  .vtourHeading {
    font-size: larger;
    font-weight: 500;
    color: #ffc400;
  }
</style>