<template>
<div>
    <Preloader v-if="preloader" />
    <video class="videostyle" controls="true" src="" ref="vodvideos" v-if="vod">
    </video>
    <iframe id="ptzframe" :src="strimurl" frameborder="0" allowfullscreen v-if="vod == false"></iframe>

    <table id="ptznavigataios" v-if="current_cam.ptz == 1 && vod == false">
        <tr>
            <td>
                <v-icon class="tt" @click="rotted('LeftUp', -50, 50, 0)">mdi-arrow-top-left</v-icon>
            </td>
            <td>
                <v-icon class="toparrow" @click="rotted('Up', 0, 50, 0)">mdi-arrow-up</v-icon>
            </td>
            <td>
                <v-icon class="tt" @click="rotted('RightUp', 50, 50, 0)">mdi-arrow-top-right</v-icon>
            </td>
        </tr>
        <tr>
            <td>
                <v-icon class="leftarrow" @click="rotted('Left', -50, 0, 0)">mdi-arrow-left</v-icon>
            </td>
            <td>
                <v-icon class="homebuttons" @click="rotted('GotoPreset', 0, 0, 0)">mdi-home</v-icon>
            </td>
            <td>
                <v-icon class="rightarrow" @click="rotted('Right', 50, 0, 0)">mdi-arrow-right</v-icon>
            </td>
        </tr>
        <tr>
            <td>
                <v-icon class="tt" @click="rotted('LeftDown', -50, -50, 0)">mdi-arrow-bottom-left</v-icon>
            </td>
            <td>
                <v-icon class="bottomarrow" @click="rotted('Down', 0, -50, 0)">mdi-arrow-down</v-icon>
            </td>
            <td>
                <v-icon class="tt" @click="rotted('RightDown', 50, -50, 0)">mdi-arrow-bottom-right</v-icon>
            </td>

        </tr>
    </table>
    <div class="zoomoutbuttons">
        <v-icon large @click="rotted('zoon in', 0, 0, -5)">mdi-minus-circle</v-icon>
    </div>
    <div class="zoominbuttons">
        <v-icon large @click="rotted('zoom out', 0, 0, 5)">mdi-plus-circle</v-icon>
    </div>
    <div class="gotobuttons" v-if="current_cam.cctv" @click="ptztoglaction" title="Go to PTZ camera">
        <v-icon large>mdi-arrow-right-bold</v-icon>
    </div>
    <div class="ptzcmbox" v-if="ptztogle">
         <p> <a class="btnppt" target="_blank" :href="`http://${current_cam.cctv_port}/doc/index.html#/portal/login`">Go to PTZ Camera</a> </p>
         <p class="tc">ID : {{current_cam.cctv_id}} <v-icon x-small @click="copyText(current_cam.cctv_id)">mdi-content-copy</v-icon> </p>
         <p class="tc">Pass : {{current_cam.cctv_pwd}} <v-icon @click="copyText(current_cam.cctv_pwd)" x-small>mdi-content-copy</v-icon></p>
         <span v-if="copied" style="color: green;">Copied!</span>
    </div>
    <div class="zoominbuttons" v-if="vod">
        <v-icon large @click="returnHome()">mdi-home</v-icon>
    </div>
    <div class="voditems">
        <v-icon large @click="vodselectDate()">mdi-table-clock</v-icon>
    </div>
    <v-dialog v-model="dialog" scrollable max-width="600px">
        <v-card>
            <v-date-picker :color="activecolor" @change='getSelectedDate($event)' v-model="picker" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>

            <v-divider></v-divider>
            <v-chip-group active-class="amber accent-3-darken-3--text text--accent-4" :color="activecolor" column class="chipbox">
                <v-dialog v-model="timeslotloader" persistent width="300">
                    <v-card :color="activecolor" dark>
                        <v-card-text>
                            {{lang.Pleasestandby}}
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-chip v-for="(item, i) in timeslots" :key="i" @change="selectTimeSlot(item.Sign_url)">{{ item.started_at }}</v-chip>
            </v-chip-group>
            <v-card-actions>
                <v-btn color="green darken-1" text @click="dialog = false">
                    {{lang.Close}}
                </v-btn>
                <v-btn color="green darken-1" text @click="dialog = false">
                    {{lang.Done}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :multi-line="multiLine" top right timeout="3000">
        {{ text }}

        <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{ lang.Close}}
            </v-btn>
        </template>
    </v-snackbar>
</div>
</template>

<script>
import axios from 'axios'
import Preloader from '../../components/PreLoader'
import {
    locals
} from "../../utils/locals";

export default {
    components: {
        Preloader
    },
    name: "Cctv",
    data() {
        return {
            preloader: true,
            current_cam: {},
            strimurl: '',
            dialog: false,
            picker: new Date().toISOString().substr(0, 10),
            activecolor: 'amber accent-3',
            timeslots: [],
            timeslotloader: false,
            vod: false,
            dateSelected: false,
            multiLine: false,
            snackbar: false,
            text: `Something went wrong, please try again.`,

            Local: locals,
            lang: {},
            ptztogle: 0,
            textToCopy: '', // The text you want to copy
          copied: false,  // Feedback after copying
        };
    },
    mounted() {
        this.initViewer();
        this.userdata = JSON.parse(localStorage.getItem('userdata'))
        // if (this.userdata.open_access == 1) {
        //     this.$router.push('/dashboard')
        // }
    },
    watch: {
        '$store.state.currentCamera': function () {

            //  this.preloader = false
            //console.log("Current data get",this.$store.state.currentCamera)
            this.current_cam = this.$store.state.currentCamera;
            this.strimurl = 'https://live.opticvyu.com:5443/LiveApp/play.html?name=' + this.current_cam.channel_1;
            if (this.current_cam.cctv == "0") {
                this.$router.push('/')
            }
            console.log("watch stream channel", this.current_cam.channel_1, this.strimurl);
        }
    },
    methods: {
        initViewer() {

            var selectleng = this.$cookies.get('currentLanguage');
            this.lang = locals[selectleng];

            this.current_cam = this.$store.state.currentCamera;
            if (this.current_cam.cctv == "0") {
                this.$router.push('/')
            }
            console.log("init Viewer");
            this.preloader = false,
                this.strimurl = 'https://live.opticvyu.com:5443/LiveApp/play.html?name=' + this.current_cam.channel_1
            console.log("init stream channel", this.current_cam.channel_1, this.strimurl);
        },
        rotted(dir, a1, a2, a3) {
            this.preloader = true;
            var d = a1 + ":" + a2 + ":" + a3;
            console.log("this.current_cam",dir);
            var camera_id = this.current_cam.camera_id;
           var _self = this;
            axios.get('/camera/' + camera_id + '/newptzControll', {
                    params: {
                        ptzc: d,
                    }
                })
                .then(function (response) {
                    console.log(response, 'Authenticated for start');
                    _self.preloader = false;
                }).catch(function (error) {
                    console.log(error, 'Error on Authentication');
                    _self.preloader = false;
                });
        },
        zoomIn() {
            this.preloader = true;
            var d = "action=start&channel=1&code=ZoomTele&arg1=0&arg2=0&arg3=0";
            var d2 = "action=stop&channel=1&code=ZoomTele&arg1=0&arg2=0&arg3=0";

            // console.log("this.current_cam",this.current_cam);
            var camera_id = this.current_cam.camera_id;
            var cctvid = this.current_cam.cctv_id;
            var cctvpwd = this.current_cam.cctv_pwd;
            var cctvport = this.current_cam.cctv_port;
            var self = this;
            axios.get('/camera/' + camera_id + '/ptzControll', {
                    params: {
                        ptzc: d,
                        cctvid: cctvid,
                        cctvpwd: cctvpwd,
                        cctvport: cctvport
                    }
                })
                .then(function (response) {
                    console.log(response, 'Authenticated for start');
                }).catch(function (error) {
                    console.log(error, 'Error on Authentication');
                });

            setTimeout(function () {
                axios.get('/camera/' + camera_id + '/ptzControll', {
                    params: {
                        ptzc: d2,
                        cctvid: cctvid,
                        cctvpwd: cctvpwd,
                        cctvport: cctvport
                    }
                }).then(function (response) {
                    self.preloader = false;
                    console.log(response, 'Authenticated for stope');
                }).catch(function (error) {
                    console.log(error, 'Error on Authentication');
                });
            }, 1000);
        },
        zoomOut() {
            this.preloader = true;
            var d = "action=start&channel=1&code=ZoomWide&arg1=0&arg2=0&arg3=0";
            var d2 = "action=stop&channel=1&code=ZoomWide&arg1=0&arg2=0&arg3=0";

            //console.log("this.current_cam",this.current_cam);
            var camera_id = this.current_cam.camera_id;
            var cctvid = this.current_cam.cctv_id;
            var cctvpwd = this.current_cam.cctv_pwd;
            var cctvport = this.current_cam.cctv_port;
            var self = this;
            axios.get('/camera/' + camera_id + '/ptzControll', {
                    params: {
                        ptzc: d,
                        cctvid: cctvid,
                        cctvpwd: cctvpwd,
                        cctvport: cctvport
                    }
                })
                .then(function (response) {
                    console.log(response, 'Authenticated for start');
                }).catch(function (error) {
                    console.log(error, 'Error on Authentication');
                });

            setTimeout(function () {
                axios.get('/camera/' + camera_id + '/ptzControll', {
                    params: {
                        ptzc: d2,
                        cctvid: cctvid,
                        cctvpwd: cctvpwd,
                        cctvport: cctvport
                    }
                }).then(function (response) {
                    self.preloader = false;
                    console.log(response, 'Authenticated for stope');
                }).catch(function (error) {
                    console.log(error, 'Error on Authentication');
                });
            }, 1000);
        },
        returnHome() {
            this.vod = false;
            this.dateSelected = false
        },
        vodselectDate() {
            this.dialog = true;
            //this.vod = true;
        },
        getSelectedDate(data) {
            this.timeslotloader = true;
            this.d1 = data;
            var d = data.split("-");
            console.log(d);
            var nd = d[0] + d[1] + d[2];

            console.log(nd, this.current_cam.camera_id);

            // https://api.opticvyu.com/api/camera/2/list/images/date/20201021
            axios({
                    url: '/camera/' + this.current_cam.camera_id + '/chunks_resource/date/' + nd,
                    method: 'GET'
                })
                .then(resp => {

                    if (resp.data.success) {
                        var dd = resp.data.success;

                        this.timeslots = dd;

                        // console.log(this.timeslots);
                        //this.regulertimeline = false;
                        //this.dialog = false;
                        this.timeslotloader = false;
                        this.dateSelected = true
                    } else {
                        this.snackbar = true;
                        this.text = resp.data.error;
                        this.timeslots = []
                        this.timeslotloader = false;
                    }

                })
                .catch(err => {
                    console.log(err);
                    this.snackbar = true;

                })

        },
        selectTimeSlot(imageID) {
            // console.log("selectTimeSlotImage id", imageID);
            this.preloader = true;
            this.dialog = false;
            this.vod = true;
            this.dateSelected = false

            var self = this;
            setTimeout(function () {
                self.$refs.vodvideos.src = imageID
                self.$refs.vodvideos.play()
                self.preloader = false;

            }, 1000);

            //this.$refs.timelapsvideo.src

        },
        ptztoglaction(){
            this.ptztogle = !this.ptztogle;
        },
        async copyText(t) {
      try {
        // Use the Clipboard API
        await navigator.clipboard.writeText(t);
        this.copied = true;

        // Reset copied status after 2 seconds
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
        this.copied = false;
      }
    }
    }
};
</script>

<style>
#ptzframe,
.videostyle {
    width: 100%;
    height: 94vh;
    background: transparent;
    display: block;
    margin: auto;
}

/* table#ptznavigataios {
    position: absolute;
    bottom: 37px;
    left: calc(50% - 40px);
    z-index: 99;
    background: #ffffff;
    right: 9px;
    margin: auto;
    opacity: 0.4;
    transition:0.5s;
} */
table#ptznavigataios {
    position: fixed;
    top: 51px;
    z-index: 99;
    background: #fff;
    right: 17px;
    margin: auto;
    opacity: .4;
    transition: .5s;
    padding: 15px;
    border: 5px solid #86bc49;
    box-shadow: 0 0 20px 9px #35db007d;
    border-radius: 50%;
}
table#ptznavigataios th, table#ptznavigataios td {
    padding: 5px 7px;
}

table#ptznavigataios:hover {
    opacity: 1;
    transition: 0.5s;
}

.leftarrow {
    position: relative;
    left: -14px;

}

.toparrow {
    position: relative;
    top: -14px;

}

.bottomarrow {
    position: relative;
    bottom: -14px;

}

.rightarrow {
    position: relative;
    right: -14px;

}

.homebuttons {
    color: #e7717d !important;
    border: 1px solid #eee2e2;
    border-radius: 50% !important;
    padding: 0px;
    border-radius: 1px;
    box-shadow: 0 0 20px 9px #35db007d;
}

.tt {
    color: #87da2a !important;
}

.gotobuttons{
    position: fixed;
    right: 75px;
    top: 267px;
    z-index: 999;
    background: #ffffff;
    padding: 3px;
    border: 5px solid #97dd44;
    opacity: 0.3;
    transition: 0.5s;
    box-shadow: 0 0 20px 9px #35db007d;
    cursor: pointer;
    border-radius: 50%;
}

    .zoomoutbuttons {
    position: fixed;
    right: 74px;
    top: 212px;
    z-index: 999;
    background: #ffffff;
    padding: 3px;
    border: 5px solid #97dd44;
    opacity: 0.3;
    transition: 0.5s;
    box-shadow: 0 0 20px 9px #35db007d;
    border-radius: 50%;
}

.zoominbuttons {
    position: fixed;
    right: 131px;
    top: 212px;
    z-index: 999;
    background: #ffffff;
    padding: 3px;
    border: 5px solid #97dd44;
    opacity: 0.3;
    transition: 0.5s;
    box-shadow: 0 0 20px 9px #35db007d;
    border-radius: 50%;
}

.voditems {
    position: fixed;
    right: 19px;
    top: 212px;
    z-index: 999;
    background: #ffffff;
    padding: 3px;
    border: 5px solid #97dd44;
    opacity: 0.3;
    transition: 0.5s;
    box-shadow: 0 0 20px 9px #35db007d;
    border-radius: 50%;
}

.zoomoutbuttons:hover,
.zoominbuttons:hover,
.voditems:hover,.gotobuttons:hover {
    opacity: 1;
}

.chipbox {
    max-height: 150px;
    overflow: scroll;
}

.chipbox.v-slide-group {
    display: block;
}

.chipbox.v-chip-group .v-slide-group__content {
    padding: 4px 24px;
}
.ptzcmbox {
    position: fixed;
    top: 322px;
    right: 21px;
    background: #ccc;
    padding: 6px;
    border: 2px solid #76e132;
    border-radius: 4px;
}
a.btnppt {
    background: #ebbc08;
    display: block;
    padding: 5px 6px;
    border-radius: 5px;
    border: 2px solid #bd9b1a;
}
p.tc {
    margin: 0 !important;
}
</style>
