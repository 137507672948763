<template>
  <div>
    <Preloader v-if="preloader" />

    <v-row>
      <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
        <!-----------------For Desktop view-------------------------------->
        <v-card class="vhfix desktop">
          <v-card-title>Select Project</v-card-title>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in items" :key="i" :class="item.id == active_proid ? 'myactiaveitem' : ''"
                active-class="actiaveitem" color="amber accent-3" @click="getCamerasbyProject(item)">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.project_name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
        <!-----------------For Mobile view-------------------------------->
        <v-card class="vhfix mobileonly">
          <v-select :items="items" class="ma-2 filterb" item-text="project_name" item-value="project_name" small
            label="Select Project" dense solo v-model="selected_project" @change="getCamerasbyProject($event)"
            return-object single-line title="Select Project"></v-select>
        </v-card>
        <!-----------------For Tab view-------------------------------->
        <v-card class="vhfix tabonly">
          <v-card-title>
            Select Proj
          </v-card-title>
          <v-list class="tabonly">
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in items" :key="i" :class="item.id == active_proid ? 'myactiaveitem' : ''"
                active-class="actiaveitem" color="amber accent-3" @click="getCamerasbyProject(item)">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.project_name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <!--------------General view for all device------------------------------------>
      <v-col cols="12" xs="12" sm="9" md="6" lg="6" xl="6">
        <v-card class="minheights">
          <v-card dark flat>
            <v-card-title class="pa-2 amber accent-3">
              <h3 class="title font-weight-light text-center grow">
                {{ CurrentPname }} - {{ lang.CameraConfiguration }}
              </h3>
            </v-card-title>
          </v-card>
          <v-snackbar v-model="snackbar" center top timeout="3000" type="success">
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                {{ lang.Ok }}
              </v-btn>
            </template>
          </v-snackbar>
          <!-- <v-row>
            <v-col cols="12" sm="2" md="2"></v-col>
            <v-col cols="12" sm="2" md="2">Reboot</v-col>
            <v-col cols="12" sm="3" md="3" class="ii_margin"
              >Image Interval</v-col
            >
            <v-col cols="12" sm="3" md="3" class="bi_margin"
              >Back Image Time</v-col
            >
            <v-col cols="12" sm="2" md="2"></v-col>
          </v-row>
          <v-row class="pt-1" v-for="(item, i) in camers" :key="i">
            <v-col cols="12" sm="2" md="2">
              <strong>CAM {{ i + 1 }}</strong> <br />
              <v-btn
                small
                color="success mx-2"
                class="mr-4"
                v-on:click="sim_manage(item.camera_id)"
                >Sim Manage
              </v-btn>
            </v-col>
           
            <v-col cols="12" sm="1" md="1">
              <v-checkbox v-model="item.reboot" class="alignbox"></v-checkbox>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="item.image_interval"
                label="Interval"
                type="number"
                title="Image Interval"
                clearable
              ></v-text-field>
              
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="item.backimagetime"
                label="Back Image Time"
                title="Back Image Time"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="btn_margin" cols="12" sm="3" md="3">
              <v-btn
                small
                color="success"
                class="mr-4 sub_btn_margin"
                v-on:click="submit_req(item)"
              >
                Submit
              </v-btn>

              <v-btn
                small
                color="success"
                class="mr-4"
                v-on:click="view_note(item.camera_id)"
                >View/Add Note
              </v-btn>
            </v-col>
          </v-row> -->
          <v-row v-if="camers.length > 1">
            <v-col cols="12" md="6" v-for="(item, i) in camers" :key="i">
              <v-card class="pa-5">
                <v-row>
                  <v-col cols="12" sm="5" md="5">
                    <v-card-title> {{ item.pro_cam_serial }}</v-card-title>
                    <v-text-field v-model="item.camera_id" label="Cam id" title="Cam id" dense filled outlined readonly
                      style="width: 84px;"></v-text-field>

                  </v-col>
                  <v-col cols="12" sm="7" md="7" class="pr-0" style="margin-top: 6%;">
                    <v-btn x-small color="success" class="mr-1" v-on:click="ecam_management(item.camera_id,item.pro_cam_serial)">ECAM
                    </v-btn>
                    <v-btn x-small color="success" class="mr-1 pa-2" v-on:click="sim_manage(item.camera_id,item.pro_cam_serial)"> {{ lang.Sim
                    }}
                    </v-btn>
                    <v-btn x-small color="success" class="mr-1 pa-2" v-on:click="view_note(item.camera_id,item.pro_cam_serial)"> {{ lang.Notes
                    }}
                    </v-btn>
                    <v-btn x-small color="success" class="pa-2" v-on:click="view_status(item.camera_id, item.timezone,item.pro_cam_serial)">
                      SSH/VNC
                    </v-btn>
                    <v-text-field v-model="item.s3dir" label="S3 Directory" title="S3 Directory" dense filled outlined
                      readonly style="margin-top: 10%;"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-checkbox v-model="item.reboot" class="" :label="lang.CameraReboot"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="item.image_interval" :label="lang.ImageInterval" type="number"
                      :title="lang.ImageInterval" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-select :items="op_camtype" v-model="item.cam_type" :label="lang.CamType" item-text="title"
                      item-value="op_camtype" @change="selectus(item.cam_type)"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select :items="op_camtype_ad" v-model="item.cam_type_ad_detail" label="CamType Additional Detail"
                      item-text="title" item-value="op_camtype_ad" @change="selectus(item.cam_type_ad_detail)"></v-select>
                  </v-col>
                </v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="item.backimagetime" :label="lang.BackImageTime" :title="lang.BackImageTime"
                    clearable></v-text-field>
                </v-col>
                <v-btn small color="success" class="mr-4" v-on:click="submit_req(item)">
                  {{ lang.submit }}
                </v-btn>

                <!-- <v-btn
                small
                color="success mx-2"
                class="mr-4"
                v-on:click="sim_manage(item.camera_id)"
                >Sim Manage
              </v-btn> -->
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="camers.length == 1">
            <v-col cols="12" md="12" v-for="(item, i) in camers" :key="i">
              <v-card class="pa-5">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-card-title> {{ item.pro_cam_serial }}</v-card-title>
                  </v-col>

                  <v-col cols="12" sm="6" md="6" class="camConfigButtons">
                    <v-btn x-small color="success" class="mr-1" v-on:click="ecam_management(item.camera_id)">ECAM
                    </v-btn>
                    <v-btn x-small color="success" class="mr-1" v-on:click="view_note(item.camera_id)">
                      {{ lang.ViewAddNote }}
                    </v-btn>
                    <v-btn x-small color="success" class="mr-1" v-on:click="sim_manage(item.camera_id)">{{ lang.SimManage
                    }}
                    </v-btn>

                    <v-btn x-small color="success" v-on:click="view_status(item.camera_id, item.timezone)"> SSH/VNC
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field v-model="item.camera_id" label="Cam id" title="Cam id" dense filled outlined readonly
                      style="width: 90px;"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field v-model="item.s3dir" label="S3 Directory" title="S3 Directory" dense filled outlined
                      readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-checkbox v-model="item.reboot" class="" :label="lang.CameraReboot"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="item.image_interval" :label="lang.ImageInterval" type="number"
                      :title="lang.ImageInterval" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select :items="op_camtype" v-model="item.cam_type" :label="lang.CamType" item-text="title"
                    item-value="op_camtype" @change="selectus(item.cam_type)"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select :items="op_camtype_ad" v-model="item.cam_type_ad_detail" label="CamType Additional Detail"
                    item-text="title" item-value="op_camtype_ad" @change="selectus(item.cam_type_ad_detail)"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="item.backimagetime" :label="lang.BackImageTime" :title="lang.BackImageTime"
                    clearable></v-text-field>
                </v-col>
                <v-btn small color="success" class="mr-4" v-on:click="submit_req(item)">
                  {{ lang.submit }}
                </v-btn>

              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ lang.AddCameraNote }} - {{ this.cam_serial_number }}</span>
                <v-alert dense text :type="alertType" v-if="alert">
                  {{ alertmessage }}
                </v-alert>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" v-for="u in updatecamdata" :key="u.id">
                      <v-card>
                        <v-row>
                          <v-col cols="12" sm="6" md="6" class="username">
                            <p class="p_username">{{ u.username }}</p>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <p class="update_date">{{ u.updated_at }}</p>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <p class="p_note">{{ u.cam_note }}</p>
                          </v-col>
                        </v-row>
                        <br />
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-text-field :label="lang.AddNote" v-model="addcamnote" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  {{ lang.Close }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="UpdateCamNote">
                  {{ lang.Save }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="simdialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ lang.AssignSimcard }} - {{ this.cam_serial_number }}</span>
                <v-alert dense text :type="alertType" v-if="alert">
                  {{ alertmessage }}
                </v-alert>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-card>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <!-- <v-autocomplete
                              auto-select-first
                              clearable
                              :items="sim_all_data"
                              item-value="sim_all_data"
                              label="Add sim no."
                              item-text="simno"
                              color="yellow"
                              v-model="addsimdata"
                              @change="selectsimno($event)"
                              persistent-hint
                              return-object
                              single-line
                            ></v-autocomplete> -->
                            <v-autocomplete auto-select-first clearable :items="sim_all_data" item-value="sim_all_data"
                              :label="lang.Addsimno" item-text="simno" v-model="addsimdata" @change="selectsimno($event)"
                              persistent-hint return-object single-line :filter="onFilter">
                              <template v-slot:selection="data">
                                <v-chip v-bind="data.attrs" :input-value="data.selected">

                                  {{ data.item.simno }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <template v-if="data.item.usage_status !== 'free'">
                                  <!-- <v-list-item class="usedsim" color="#f00"> -->
                                  <!--  <v-list-item-avatar>
                                    <img src="https://lh3.googleusercontent.com/proxy/yD2StjZwl_dmLgvGnYLvFhYdVJySzKm_XHrNWb0WLSsqWD0zhJcynuoKN_6K2tFBu9k01V-iFDuky_izsMpzD03qJoshZ9FYZQ" />
                                  </v-list-item-avatar> -->
                                  <v-list-item-content class="usedsim">
                                    <v-list-item-title v-html="data.item.simno"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.sim20d_no"></v-list-item-subtitle>
                                  </v-list-item-content>
                                  <!-- </v-list-item> -->
                                </template>
                                <template v-else>
                                  <!-- <v-list-item class="freesim" color="#f00"> -->
                                  <!-- <v-list-item-avatar class="freesim">
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGIpShKC3AnB584v_F7OHfjS6jTDf0CPOfmQT_DHJ0r1uvzhdz5jQbnhIo6nRzsKyWsEM&usqp=CAU" />
                                  </v-list-item-avatar> -->
                                  <v-list-item-content class="freesim">
                                    <v-list-item-title v-html="data.item.simno"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.sim20d_no"></v-list-item-subtitle>
                                  </v-list-item-content>
                                  <!-- </v-list-item> -->
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field :label="lang.simprovider" v-model="simprovider" readonly
                              required></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field :label="lang.AddNote" v-model="addsimnote" required></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" v-if="checkusage">
                            <v-select :items="op_usage" v-model="usage_status" :label="lang.Simusagestatus"
                              item-text="title" item-value="op_usage" @change="selectus(usage_status)"
                              required></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" v-if="usage_status == 'free'">
                            <v-text-field :label="lang.ifsimfree" v-model="withwhom" required></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field :label="lang.UpdatedBy" v-model="updated_by" readonly required></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="simdialog = false">
                  {{ lang.Close }}
                </v-btn>
                <v-btn color="blue darken-1" text v-if="enablesave" @click="savesim">
                  {{ lang.Save }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="ecamdialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Assign ECAM - {{ this.cam_serial_number }}</span>
                <v-alert dense text :type="alertType" v-if="alert">
                  {{ alertmessage }}
                </v-alert>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-card>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete auto-select-first clearable :items="ecamsList" item-value="ecamsList"
                              label="mificode" item-text="mifi_code" v-model="addecamdata" @change="selectecam($event)"
                              persistent-hint return-object single-line>
                              <template v-slot:selection="data">
                                <v-chip v-bind="data.attrs" :input-value="data.selected">

                                  {{ data.item.mifi_code }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <template v-if="data.item.usage_status !== 'free'">
                                  <v-list-item-content class="usedsim">
                                    <v-list-item-title v-html="data.item.mifi_code"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.usage_status"></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content class="freesim">
                                    <v-list-item-title v-html="data.item.mifi_code"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.usage_status"></v-list-item-subtitle>
                                  </v-list-item-content>
                                  <!-- </v-list-item> -->
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field :label="lang.AddNote" v-model="addecamnote" required></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" v-if="ecam_checkusage">
                            <v-select :items="ecam_usage" v-model="ecam_usage_status" label="Usage Status"
                              item-text="title" item-value="ecam_usage" @change="selectecamusage(ecam_usage_status)"
                              required></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6" v-if="ecam_usage_status == 'free'">
                            <v-text-field :label="lang.ifsimfree" v-model="ecam_withwhom" required></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field :label="lang.UpdatedBy" v-model="ecam_updated_by" readonly
                              required></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-row style="width: 100%;" v-if="qr_code_path_signed_url">
                  <v-col cols="12" sm="6" md="6">
                    <v-img
                  height="200"
                  width="239"
                  :src="qr_code_path_signed_url"
                ></v-img>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="text-end" align-self="center">
                    <v-btn color="blue darken-1" text @click="ecamdialog = false">
                      {{ lang.Close }}
                    </v-btn>
                    <v-btn color="blue darken-1" text v-if="ecam_enablesave" @click="saveecam">
                      {{ lang.Save }}
                    </v-btn>
                  </v-col>
                </v-row>
              <div v-else>
                <v-btn color="blue darken-1" text @click="ecamdialog = false">
                      {{ lang.Close }}
                    </v-btn>
                    <v-btn color="blue darken-1" text v-if="ecam_enablesave" @click="saveecam">
                      {{ lang.Save }}
                    </v-btn>
                  </div>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- cam ssh info dialog -->
          <v-dialog v-model="sshdialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">SSH/VNC Info - {{ this.cam_serial_number }} </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" v-for="u in sshcamdata" :key="u.remoteconnection_id">
                      <v-card>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <p class="p_note"><strong>SSH Port :</strong>{{ u.sshport }} <v-icon small title="Copy"
                                color="#e1b125"
                                @click="copyMe(`ssh -N -i D:/\opticit.pem -L 201:localhost:${u.sshport} root@5.161.90.190`)">mdi-content-copy</v-icon>
                            </p>

                            <p class="p_note"><strong>VNC Port :</strong> {{ u.vncport }} <v-icon small title="Copy"
                                color="#e1b125"
                                @click="copyMe(`ssh -N -i D:/\opticit.pem -L 5900:localhost:${u.vncport} root@5.161.90.190`)">mdi-content-copy</v-icon>
                            </p>

                            <p class="p_note"> <strong>Description : </strong>{{ u.description }} </p>
                            <p class="p_note"> <strong>Time : </strong>{{ showdata }} <span
                                :class="`timesignal ${secondiff < 31 && secondiff > 0 ? 'greenc' : 'redc'}`"></span></p>
                          </v-col>
                        </v-row>
                        <br />
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="sshdialog = false">
                  {{ lang.Close }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dataToolDialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Data Download Tool </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-card>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <p class="p_note">{{ dataToolResponse }}</p>
                          </v-col>
                        </v-row>
                        <br />
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dataToolDialog = false">
                  {{ lang.Close }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-row v-if="downloadDataToolAccess">
            <v-col cols="12" md="6">
              <v-btn color="success" class="mr-4 pa-2 dataDownloadTool" v-on:click="dataDownloadTool">Data
                Download</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="3" md="3" out-line class="setting_sidebar">
        <Sidebar />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import Preloader from "../../../components/PreLoader";
import Sidebar from "../../../components/SettingSidebar";
import { locals } from "../../../utils/locals";
export default {
  components: {
    Preloader,
    Sidebar,
  },
  name: "cam_configuration",
  data() {
    return {
      preloader: false,
      items: {},
      selected_project: '',
      camers: [],
      camdata: [],
      CurrentPname: "",
      checkboxactive: false,
      img_interval: "",
      param_up: {},
      counter: "",
      snackbar: false,
      text: "Something went wrong. Please reload and try again.",
      alert: false,
      alertmessage: "",
      alertType: "",
      updatecamdata: {},
      dialog: false,
      simdialog: false,
      sim_cam_id: "",
      sim_all_data: {},
      addcamnote: "",
      notecam_id: "",
      currentprojectid: "",
      addsimnote: "",
      addsimdata: "",
      alotsim: "",
      checkusage: false,
      op_usage: [
        { op_usage: "free", title: "free" },
        { op_usage: "used", title: "used" },
      ],
      usage_status: "",
      updated_by: "",
      simprovider: "",
      withwhom: "",
      enablesave: false,
      currentProject: "",
      active_proid: "",
      cam_type: "",
      camera_name: '',
      op_camtype: [
        { op_camtype: "D3400", title: "D3400" },
        { op_camtype: "D3500", title: "D3500" },
        // {op_camtype:"Z7-II", title:"Z7-II"},
        // {op_camtype:"360", title:"360"},
        // {op_camtype:"CCTV without POE", title:"CCTV without POE"},
        // {op_camtype:"CCTV with POE", title:"CCTV with POE"},
        { op_camtype: "CCTV Integration", title: "CCTV Integration" },
        // {op_camtype:"5MP", title:"5MP"},
        // {op_camtype:"180 Degree", title:"180 Degree"},
        // {op_camtype:"PTZ", title:"PTZ"},
        // {op_camtype:"Demo Camera", title:"Demo Camera"},
        { op_camtype: "Canon 1500D", title: "Canon 1500D" },
        { op_camtype: "ECAM 4MP", title: "ECAM 4MP" },
        { op_camtype: "ECAM 8MP", title: "ECAM 8MP" },
        { op_camtype: "ECAM 18MP", title: "ECAM 18MP" }
      ],
      op_camtype_ad: [
        { op_camtype_ad: "10-20mm Lens", title: "10-20mm Lens" },
        { op_camtype_ad: "18-55mm Lens", title: "18-55mm Lens" },
        { op_camtype_ad: "70-300mm Lens", title: "70-300mm Lens" },
        // {op_camtype_ad:"Hikvision", title:"Hikvision"},
        // {op_camtype_ad:"Dahua", title:"Dahua"},
        // {op_camtype_ad:"CPPlus", title:"CPPlus"},
        // {op_camtype_ad:"Milesight - 180°", title:"Milesight - 180°"},
        { op_camtype_ad: "Reportftp Server", title: "Reportftp Server" },
        { op_camtype_ad: "HFTP Server", title: "HFTP Server" },
        { op_camtype_ad: "HFTP1 Server", title: "HFTP1 Server" },
        { op_camtype_ad: "HFTP2 Server", title: "HFTP2 Server" },
        { op_camtype_ad: "Reportftp1 Server", title: "Reportftp1 Server" }
      ],
      Local: locals,
      lang: {},
      sshdialog: false,
      sshcamdata: '',
      showdata: '',
      secondiff: 0,
      dataToolDialog: false,
      dataToolResponse: '',
      downloadDataToolAccess: false,
      ecamdialog: false,
      ecamsList: {},
      ecam_enablesave: false,
      ecam_checkusage: false,
      addecamdata: "",
      addecamnote: "",
      ecam_usage_status: '',
      ecam_updated_by: "",
      ecam_withwhom: "",
      ecam_camera_id: "",
      ecam_usage: [
        { ecam_usage: "free", title: "free" },
        { ecam_usage: "used", title: "used" },
      ],
      qr_code_path: '',
      qr_code_path_signed_url: '',
      cam_serial_number: '',
    };
  },
  mounted() {
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    if (this.userdata.open_access == 1) {
      this.$router.push('/dashboard')
    }
    var selectleng = this.$cookies.get('currentLanguage');
    this.lang = locals[selectleng];
    this.getPorjects();
    this.current_cam = this.$store.state.currentCamera;
    this.camera_name = this.current_cam.pro_cam_serial;
    //console.log("userdata", this.userdata.email);
    this.updated_by = this.userdata.email;
    this.ecam_updated_by = this.userdata.email;
    // if (this.userdata.free_access == 0 && this.userdata.opticvyu_user == 1) {
    //   this.useraccess = true;
    // } else {
    //   this.$router.push("/");
    // }
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
    this.active_proid = this.currentProject.id;
    if (this.userdata.email === 'anshul.jain@opticvyu.com' || this.userdata.email === 'nitesh.patel@opticvyu.com') {
      this.downloadDataToolAccess = true;
    }
  },
  watch: {
    "$store.state.currentCamera": function () {
      //console.log("Current data get", this.$store.state.currentCamera);
      this.camers = [];
      this.current_cam = this.$store.state.currentCamera;
      this.camera_name = this.current_cam.pro_cam_serial;
      //this.checkboxactive  =  this.current_cam.reboot == 1 ? true: false;
      // this.img_interval  = this.current_cam.image_interval;
      this.currentProject = JSON.parse(localStorage.getItem("currentProject"));
      this.active_proid = this.currentProject.id;
    },
  },
  computed: {
    params() {
      return {
        interval_update: this.img_interval,
        reboot_update: this.checkboxactive ? 1 : 0,
      };
    },
    param_add() {
      return {
        camera_note: this.addcamnote,
        camera_id: this.notecam_id,
      };
    },
    sim_param_assign() {
      return {
        sim_note: this.addsimnote,
        sim_camera_id: this.sim_cam_id,
        sim_project_id: this.currentprojectid,
        sim_projectname: this.CurrentPname,
        simnoadd: this.addsimdata,
        update_by: this.userdata.email,
        simusage: this.usage_status,
        withwhom: this.withwhom,
      };
    },
    ecam_param_assign() {
      return {
        ecam_note: this.addecamnote,
        ecam_camera_id: this.ecam_camera_id,
        ecam_project_id: this.currentprojectid,
        ecam_projectname: this.CurrentPname,
        mificode: this.addecamdata,
        ecam_update_by: this.userdata.email,
        ecamusage: this.ecam_usage_status,
        withwhom: this.ecam_withwhom,
        qr_code_path: this.qr_code_path,
      };
    },
  },
  methods: {
    getPorjects: function () {
      axios({ url: "/project", method: "GET" })
        .then((resp) => {
          // console.log("Projects", resp.data.success);
          this.items = resp.data.success.filter(function (item) {
            return item.project_active == 1;
          });

          //this.getCamerasbyProject(this.items[0]);
          this.getCamerasbyProject(this.currentProject);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCamerasbyProject(projectId) {
      this.preloader = true;
      this.active_proid = projectId.id || this.selected_project.id;
      // console.log(projectId);
      this.CurrentPname = projectId.project_name || this.selected_project.project_name;
      this.currentprojectid = projectId.id || this.selected_project.id;
      this.camers = {};
      this.camdata = [];
      axios({ url: "/camera/" + this.active_proid, method: "GET" })
        .then((resp) => {
          //console.log('get cameras by project', resp.data.success);
          this.camers = resp.data.success.filter(function (item) {
            console.log("check camera", item);
            return item.camera_active == 1;

          });
          var len = 0;
          if (resp.data.success != null) {
            len = resp.data.success.length;
          }
          if (len > 0) {
            //console.log("count", resp.data.success.length);
            for (var i = 0; i < len; i++) {
              if (
                this.current_cam.camera_id == resp.data.success[i].camera_id
              ) {
                var camnumber = i + 1;
                console.log(camnumber);
              }
              this.checkboxactive =
                resp.data.success[i].reboot == 1 ? true : false;
              this.img_interval = resp.data.success[i].image_interval;
            }
          }
          // console.log("Cameras", this.camers);
          var a = 1;
          this.camers.forEach((cam) => {
            this.getLattersImage(a, cam);
            //this.counter++;

            a++;
          });
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    view_note(camera_id,pro_cam_serial) {
      this.dialog = true;
      this.notecam_id = camera_id;
      axios({
        url: "/camera/" + this.notecam_id + "/get_add_note",
        method: "GET",
      })
        .then((resp) => {
          this.cam_serial_number = pro_cam_serial;
          this.updatecamdata = resp.data.success;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sim_manage(camera_id,pro_cam_serial) {
      this.simdialog = true;
      this.sim_cam_id = camera_id;
      axios({
        url: "/camera/" + this.sim_cam_id + "/get_all_sim_data",
        method: "GET",
      })
        .then((resp) => {
          this.sim_all_data = resp.data.success;
          console.log("this.sim_all_data", this.sim_all_data);
          if (resp.data.success.length > 1) {
            this.alert = false;
            this.enablesave = false;
            this.checkusage = false;
            this.addsimdata = "";
            this.addsimnote = "";
            this.usage_status = "";
            this.updated_by = this.userdata.email;
            this.cam_serial_number = pro_cam_serial;
            (this.simprovider = ""),
              (this.withwhom = ""),
              console.log("get all sim", this.sim_all_data);
          } else {
            this.alert = false;
            this.enablesave = true;
            this.checkusage = true;
            this.addsimdata = resp.data.success[0].simno;
            this.addsimnote = resp.data.success[0].comments;
            this.usage_status = resp.data.success[0].usage_status;
            this.updated_by = resp.data.success[0].updated_by;
            (this.simprovider = resp.data.success[0].service_provider),
              (this.withwhom = resp.data.success[0].if_free_with_whom),
              console.log("already alotted", this.sim_all_data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },


    selectsimno(getsimdata) {
      console.log("passing sim data", getsimdata);
      if (
        getsimdata.usage_status == "usage" ||
        getsimdata.usage_status == "used"
      ) {
        // console.log("passing sim data", getsimdata.usage_status);
        this.enablesave = false;
        this.simprovider = getsimdata.service_provider;
        this.alert = true;
        this.alertmessage =
          "The SIM card is already used for " +
          getsimdata.project_name +
          " Project Camera";
        this.alertType = "error";
        // alert("Not a free sim");
      } else {
        //console.log("passing sim data", getsimdata.usage_status);
        //alert("yes free sim");
        this.alert = false;
        this.simprovider = getsimdata.service_provider;
        this.addsimdata = getsimdata.simno;
        this.enablesave = true;
      }
    },

    savesim() {
      console.log("save_simparam", this.sim_param_assign);
      axios({
        url: "/camera/" + this.sim_cam_id + "/post_add_update_simcam_data",
        data: this.sim_param_assign,
        method: "POST",
      })
        .then((resp) => {
          if (resp.data.success) {
            this.simdialog = false;
            this.snackbar = true;
            this.text = resp.data.success;
            /* this.alert = true;
            this.alertmessage = resp.data.success;
            this.alertType = "success"; */
          } else {
            this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error";
          }
          /* var self = this;
          setTimeout(function () {
            self.dialog = false;
            self.alert = false;
          }, 1000); */
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdateCamNote(pro_cam_serial) {
      //console.log("data to update", this.param_add);
      //camera_idd = this.notecam_id;
      axios({
        url: "/camera/" + this.notecam_id + "/post_add_note",
        data: this.param_add,
        method: "POST",
      })
        .then((resp) => {
          if (resp.data.success) {
            this.alert = true;
            this.cam_serial_number = pro_cam_serial;
            this.alertmessage = resp.data.success;
            this.alertType = "success";
            this.addcamnote = "";
            this.view_note(this.notecam_id);
          } else {
            this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error";
          }
          /* var self = this;
          setTimeout(function () {
            self.dialog = false;
            self.alert = false;
          }, 1000); */
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLattersImage() {
      // console.log("lates images cam data",camdata);
    },
    view_status(cids, timezone, pro_cam_serial) {

      this.sshcamdata = '';
      this.preloader = true;
      axios({
        url: "/cam-confige-ssh-vnc",
        params: {
          camid: cids
        },
        method: "get",
      })
        .then((resp) => {
          console.log("result", resp);
          this.sshcamdata = resp.data;
          this.cam_serial_number = pro_cam_serial;
          this.sshdialog = true;
          this.snackbar = true;


          var dateUTC = new Date(resp.data[0].ip_updated_on);  // You can replace this with your UTC timestamp

          //   var dateUTC = new Date("yourInputDateInASuitableFormat");
          dateUTC = dateUTC.getTime();
          var dateIST = new Date(dateUTC);
          //date shifting for IST timezone (+5 hours and 30 minutes)
          dateIST.setHours(dateIST.getHours() + 5);
          dateIST.setMinutes(dateIST.getMinutes() + 30);
          console.log("dateIST", dateIST.toString());

          //console.log("camera_id",item,i);

          var dateISTs = dateIST.getTime();

          var standTime = new Date(dateISTs);

          console.log("standTime===", standTime);

          var oprt = timezone.substr(0, 1);

          standTime.setHours(standTime.getHours() + 0);
          standTime.setMinutes(standTime.getMinutes() + 0);

          if (oprt == "+" || oprt == "-") {
            var timexz = timezone.substr(1, 5);

            var tps = timexz.split(":");

            var timeh = parseInt(tps[0]);
            var timem = parseInt(tps[1]);
            if (oprt == "+") {
              console.log("timeh", timeh);
              console.log("timem", timem);
              standTime.setHours(standTime.getHours() + timeh);
              standTime.setMinutes(standTime.getMinutes() + timem);
            } else {
              console.log("timeh", timeh);
              console.log("timem", timem);
              standTime.setHours(standTime.getHours() - timeh);
              standTime.setMinutes(standTime.getMinutes() - timem);
            }
          }

          // console.log("oprt",oprt);

          console.log("standTime dateIST", standTime);


          const inputDateString = standTime;

          // Parse the input date string
          const inputDate = new Date(inputDateString);

          // Function to add leading zero if a number is less than 10
          function addLeadingZero(number) {
            return number < 10 ? `0${number}` : number;
          }

          // Format the date
          //const formattedDate = `${this.addLeadingZero(inputDate.getDate())}-${this.addLeadingZero(inputDate.getMonth() + 1)}-${inputDate.getFullYear()} ${this.addLeadingZero(inputDate.getHours())}:${this.addLeadingZero(inputDate.getMinutes())}:${this.addLeadingZero(inputDate.getSeconds())}`;
          const formattedDate = `${addLeadingZero(inputDate.getDate())}-${addLeadingZero(inputDate.getMonth() + 1)}-${inputDate.getFullYear()} ${addLeadingZero(inputDate.getHours())}:${addLeadingZero(inputDate.getMinutes())}:${addLeadingZero(inputDate.getSeconds())}`;

          console.log(formattedDate);
          this.showdata = formattedDate;
          ///this.secondiff = addLeadingZero(inputDate.getSeconds());


          const givenDatetimeString = inputDateString; // Example format: ISO 8601

          // Convert the given datetime string to a Date object
          const givenDatetime = new Date(givenDatetimeString);

          // Get the current datetime
          const currentDatetime = new Date();

          // Calculate the time difference in milliseconds
          const timeDifferenceMs = currentDatetime - givenDatetime;

          // Calculate the time difference in days, hours, minutes, and seconds
          const days = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDifferenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifferenceMs % (1000 * 60)) / 1000);

          // Format the time difference
          const formattedTimeDifference = `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;

          console.log(formattedTimeDifference);

          this.secondiff = minutes + seconds;

          //  const date = new Date(standTime);

          // const day = date.getDate().toString().padStart(2, '0');
          // const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
          // const year = date.getFullYear().toString();

          // const formattedDate = `${day} ${month} ${year}`;
          // console.log(formattedDate); // Output: "21 08 2023"

          // // Create a Date object from the UTC timestamp
          // const utcDate = new Date(resp.data[0].updated_on);

          // // Get the UTC offset in minutes
          // const utcOffset = utcDate.getTimezoneOffset();

          // // IST offset is UTC +5 hours and 30 minutes
          // const istOffset = -330;

          // // Calculate the new timestamp in IST
          // const istTimestamp = utcTimestamp + (utcOffset + istOffset) * 60 * 1000;

          // // Create a Date object from the IST timestamp
          // const istTime = new Date(istTimestamp);

          // console.log("UTC Timestamp:", utcDate.toUTCString());
          // console.log("IST Timestamp:", istTime.toString());

          // //const istTime = new Date('2023-08-21T12:00:00+05:30');
          // const gmtOffset = 2.5 * 60 * 60 * 1000; // +2.5 hours in milliseconds
          // const gmtTime = new Date(istTime.getTime() + gmtOffset);

          // // Display the result
          // console.log('IST Time:', istTime.toISOString());
          // console.log('GMT (+2.30) Time:', gmtTime.toISOString());

          if (resp.data.length > 0) {
            this.text = "Connection established for 30 mins.";
            this.view_status_update(cids);
          } else {
            this.text = "Record not found.";
          }
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    copyMe(cc) {
      navigator.clipboard.writeText(cc);
      this.snackbar = true;
      this.text = "Copied.";

    },
    view_status_update(cid) {

      axios({
        url: "/cam-confige-ssh-vnc-status",
        params: {
          camid: cid
        },
        method: "get",
      })
        .then((resp) => {

          console.log("result", resp);
          this.preloader = false;
        })
        .catch((err) => {
          console.log(err);
          this.preloader = false;
        });
    },
    submit_req(item) {
      if (item.reboot == true) {
        item.reboot = 1;
      } else if (item.reboot == false) {
        item.reboot = 0;
      } else {
        item.reboot;
      }
      this.param_up = {
        cam_id_up: item.camera_id,
        image_interval: item.image_interval,
        reboot: item.reboot,
        backimagetime: item.backimagetime,
        cam_type: item.cam_type,
        cam_type_ad: item.cam_type_ad_detail,
      };
      //console.log("data to update", this.param_up);
      axios({
        url: "/camera/" + item.camera_id + "/update_param",
        data: this.param_up,
        method: "POST",
      })
        .then((resp) => {
          //console.log("result", resp.data.success);
          if (resp.data.success == "Successfull") {
            this.snackbar = true;
            this.text = this.lang.camsetting;
            setTimeout(() => {
              location.reload();
            }, 3000);
          } else {
            this.snackbar = true;
            this.text = this.lang.camfialdsetting;
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          this.alert = true;
          this.alertmessage = this.lang.SomethingWentWrong;
          this.alertType = "error";
          setTimeout(() => {
            location.reload();
          }, 3000);
        });
    },
    dataDownloadTool() {
      var projectName = JSON.parse(localStorage.getItem("currentProject"));
      let spacesRemovedProjectName = projectName.project_name.replace(/\s/g, "");
      let values = [];
      this.camers.forEach(obj => {
        if (obj.s3dir) {
          values.push(obj.s3dir);
        }
      });
      let str = values.join(",");

      var toolData = {
        policy_code: "Client_" + spacesRemovedProjectName,
        user_id: "Client_" + spacesRemovedProjectName,
        dirs: str
      }

      axios({
        url: "/actionForDataDownloadTool",
        data: toolData,
        method: "POST"
      })
        .then((resp) => {
          if (resp.data.success) {
            if (resp.data.success.error) {
              this.dataToolResponse = "Cannot create new entity. Please check if the entity already exist for the project.";
              this.dataToolDialog = true;
            } else {
              this.dataToolResponse = resp.data.success;
              this.dataToolDialog = true;
            }
          } else {
            this.snackbar = true;
            this.text = "Something Went Wrong";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFilter(item, queryText) {
      const keyMatch = item.simno.toString().toLowerCase().indexOf(queryText.toLowerCase()) > -1;
      const valueMatch = item.sim20d_no.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
      return keyMatch || valueMatch;
    },
    ecam_management(camera_id,pro_cam_serial) {
      this.ecamdialog = true;
      this.ecam_camera_id = camera_id;
      axios({
        url: "/camera/" + this.ecam_camera_id + "/ecam/list",
        method: "GET",
      })
        .then((resp) => {
          this.ecamsList = resp.data;
          if (resp.data.length > 1) {
            this.alert = false;
            this.ecam_enablesave = false;
            this.ecam_checkusage = false;
            this.addecamdata = "";
            this.addecamnote = "";
            this.ecam_usage_status = "";
            this.ecam_updated_by = this.userdata.email;
            this.qr_code_path = "";
            this.qr_code_path_signed_url = "";
            this.cam_serial_number = pro_cam_serial;
            (this.ecam_withwhom = "")
          } else {
            this.alert = false;
            this.ecam_enablesave = true;
            this.ecam_checkusage = true;
            this.addecamdata = resp.data[0].mifi_code;
            this.addecamnote = resp.data[0].comments;
            this.ecam_usage_status = resp.data[0].usage_status;
            this.ecam_updated_by = this.userdata.email;
            this.qr_code_path = resp.data[0].qr_code_path;
            this.qr_code_path_signed_url = resp.data[0].qr_code_path_signed;
            this.cam_serial_number = pro_cam_serial;
            (this.ecam_withwhom = resp.data[0].if_free_with_whom);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectecam(getecamata) {
      if (getecamata.usage_status == "used") {
        this.ecam_enablesave = false;
        this.alert = true;
        this.alertmessage =
          "The ECAM is already used for " +
          getecamata.project_name +
          " Project Camera id " + getecamata.camera_id;
        this.alertType = "error";
      } else {
        this.alert = false;
        this.addecamdata = getecamata.mifi_code;
        this.qr_code_path = getecamata.qr_code_path;
        this.ecam_enablesave = true;
      }
    },
    saveecam() {
      axios({
        url: "/camera/" + this.ecam_camera_id + "/ecam/usage",
        data: this.ecam_param_assign,
        method: "POST",
      })
        .then((resp) => {
          if (resp.data.success) {
            this.ecamdialog = false;
            this.snackbar = true;
            this.text = resp.data.success;
          } else {
            this.alert = true;
            this.alertmessage = resp.data.error;
            this.alertType = "error";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.vhfix {
  overflow: auto;
}

.minheights {
  min-height: 91vh;
}

.alignbox {
  margin-top: -3%;
}

.mx-2 {
  font-size: 10px !important;
}

.username {
  color: #f3bd08;
  font-weight: 500;
  font-size: 16px;
}

.update_date {
  text-align: right;
  margin-right: 3%;
}

.p_username {
  margin-left: 4%;
}

.p_note {
  margin-left: 4%;
}

.btn_margin {
  margin-left: -3%;
}

.pt-1 {
  /* margin-top: 2%; */
  margin-left: 2%;
  margin-bottom: 4%;
}

.sub_btn_margin {
  margin-top: -28%;
}

.ii_margin {
  margin-left: -4%;
}

.bi_margin {
  margin-left: -2%;
}

.freesimnostyle {
  background-color: #4caf50 !important;
}

.usedsim {
  background: #d88585;
  padding-left: 15px;
  /* padding-bottom: 11px; */
  margin-left: -15px;
  margin-right: -15px;
}

.freesim {
  background: #9cdf9c;
  padding-left: 15px;
  /* padding-bottom: 11px; */
  margin-left: -15px;
  margin-right: -15px;
}

.myactiaveitem {
  color: #ffc400 !important;
  caret-color: #ffc400 !important;
  background-color: #fff8e1 !important;
}

.timesignal {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  position: relative;
  top: 4px;
  left: 9px;
}

.greenc {
  background-color: green;
  box-shadow: 0 0 8px 6px #958f8f;
  border: 2px solid #ccc;
}

.redc {
  background-color: rgb(240, 50, 50);
  box-shadow: 0 0 8px 6px #958f8f;
  border: 2px solid #ccc;
}

.dataDownloadTool {
  margin: 10px 21px;
}

.camConfigButtons {
  padding: 0px;
  margin-top: 28px;
}
</style>

